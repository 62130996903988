import React, { useState, useEffect } from 'react';
import Button from '@unity/react-components/button';
import PageHeader from '@unity/react-components/page-header/page-header';
import metricsDataAPI from '../data/metrics-data-api';
import StateCard from '../modified-unity-library-components/state-card';
import MetricsUtilizationTable from '../components/metrics-page/metrics-utilization-table';
import MetricsTransactionsTable from '../components/metrics-page/metrics-transactions-table';
import metricsHealthInstance from '../data/metrics-health';
import { makeCancelable } from '../helpers/cancellable-promise';
import { ResponseError } from '../data/api-cache';

const MetricsPage = () => {
  const [reload, setReload] = useState(1);
  const [didRefresh, setDidRefresh] = useState(false);
  const [requestState, setRequestState] = useState({
    areMetricsReady: false,
    showError: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (!didRefresh) {
      metricsDataAPI.refresh();
      setDidRefresh(true);
    }
    // Metrics takes some time to fully load since the server take a minute
    // to write it to disk when first starting up.
    const metricsRequest = makeCancelable(metricsHealthInstance.notifyWhenMetricsReady());
    metricsRequest.promise.then(() => {
      setRequestState((prevState) => ({
        ...prevState,
        areMetricsReady: true,
        showError: false,
      }));
    }).catch((error) => {
      setRequestState((prevState) => ({
        ...prevState,
        areMetricsReady: false,
        showError: true,
        errorMessage: ResponseError.message(error),
      }));
    });

    return function cleanup() {
      metricsRequest.cancel();
      setDidRefresh(false);
    };
  }, []);

  function onRefreshClick() {
    metricsDataAPI.refresh();
    setReload(reload + 1);
  }

  const actions = (
    <Button color="primary" variant="contained" disabled={!requestState.areMetricsReady} onClick={() => onRefreshClick()}>Refresh</Button>
  );

  function metricsUIContent() {
    let template = null;
    if (requestState.showError) {
      template = (
        <StateCard
          title="Failed Loading Accelerator Metrics"
          description={requestState.errorMessage}
        />
      );
    } else if (requestState.areMetricsReady) {
      template = (
        <div key={reload}>
          <MetricsUtilizationTable />
          <MetricsTransactionsTable />
        </div>
      );
    } else {
      template = (
        <StateCard
          title="Loading Accelerator Metrics"
          description="May take up to a minute for initial collection..."
          isLoading
        />
      );
    }
    return template;
  }

  // key={} attribute forces re-initialization of nested components.
  // https://stackoverflow.com/questions/21749798/how-can-i-reset-a-react-component-including-all-transitively-reachable-state
  return (
    <div>
      <PageHeader title="Metrics" actions={actions} />
      {metricsUIContent()}
    </div>
  );
};

export default MetricsPage;
