import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';
import configurationDataAPI from '../../../data/configuration-data-api';
import { makeCancelable } from '../../../helpers/cancellable-promise';
import TextInput from '../../on-change-textinput';
import ErrorText from '../../../app-wide-controls/error-text';
import { ResponseError } from '../../../data/api-cache';

const TextEditor = (props) => {
  const [uiContent, setUIContent] = useState(null);
  const [isLoadingState, setIsLoadingState] = useState(true);

  function setIsLoading(isLoading) {
    setIsLoadingState(isLoading);
    if (props.isLoadingListener) {
      props.isLoadingListener(isLoading);
    }
  }

  function handleEdits(textChange) {
    props.onEditListener({ [props.dataID]: textChange });
  }

  useEffect(() => {
    setIsLoading(true);
    let fetchRequest;
    (async function () {
      try {
        fetchRequest = makeCancelable(configurationDataAPI.valuesForKeys(props.dataID));
        const { [props.dataID]: data } = await fetchRequest.promise;
        props.onEditListener(data);
        const content = <TextInput text={data.toDisplay(true /* display as input */)} onChange={handleEdits} />;
        setIsLoading(false);
        setUIContent(content);
      } catch (error) {
        const message = ResponseError.message(error);
        const content = (_props) => (<ErrorText text={message} />);
        setIsLoading(false);
        setUIContent(content);
      }
    }());

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
    };
  }, [props.dataID]);


  function UI() {
    if (isLoadingState) {
      return (<SkeletonLoader width={250} height={20} gutterBottom="small" rounded />);
    }
    return uiContent;
  }

  return (
    <>
      {UI()}
    </>
  );
};

TextEditor.propTypes = {
  dataID: PropTypes.string,
  onEditListener: PropTypes.func.isRequired,
  isLoadingListener: PropTypes.func,
};

const styles = ({ unityTypography }) => ({
  caption: unityTypography.caption,
});

export default withStyles(styles)(TextEditor);
