import metricsDataAPI from './metrics-data-api';
import configurationDataAPI from './configuration-data-api';


export const fetchData = (configIds, metricIds) => {
  const metricPromises = metricsDataAPI.valuesForKeys(metricIds);
  const configPromises = configurationDataAPI.valuesForKeys(configIds);

  return new Promise((resolve, reject) => {
    Promise.all([configPromises, metricPromises]).then((values) => {
      resolve({ configValues: values[0], metricValues: values[1] });
    }).catch((error) => {
      reject(error);
    });
  });
};
