import appAxios from './app-axios';
import { IN_PROGRESS_STATE, COMPLETED_STATE, FAILED_STATE } from '../helpers/constants';

const API_CACHE_ALL_KEYS = '*';

/* eslint max-classes-per-file: ["error", 3] */

/**
 * Makes a request to one API endpoint for a large
 * object.  Thereafter handles key requests into that object.
 * - Data can be refreshed from network.
 * - Saving to keys makes a POST and notifies listeners of successful update.
 * - APICache is intended to be subclassed, to:
 * -- Receive data loading and refreshes,
 * (this._data is not updated here. Allows subclass data processing, translating...),
 * -- Handle the result of every key request or update/save: fn(data, caller),
 * -- Provide URL path
 */
export default class APICache {
  constructor(path) {
    this._path = path;
    this._data = null;
    this._isRefreshing = false;

    // Hold onto requests during refreshing or before
    // loading data, and respond when ready.
    this._notifications = {

      // Requests received during refreshing. Emptied on fulfillment.
      // Served in order received. Subclasses receive:
      // (see _createPendingResponse())
      pendingKeyRequests: [],

      // Callers wanting updates when key/value is updated
      // via successful save(s) (POST).  Listeners indexed by key.
      keyListeners: {},
    };
  }

  async refresh() {
    if (this._isRefreshing) {
      return {
        state: IN_PROGRESS_STATE,
        error: undefined,
      };
    }
    this._isRefreshing = true;
    const status = await this._fetchFromNetwork(this._path);
    return status;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  async _fetchFromNetwork(path) {
    const self = this;
    try {
      const { data } = await appAxios.get(path);
      self._onDataChange(data);
      self._isRefreshing = false;
      self._notifications.pendingKeyRequests.forEach((pendingResponse) => {
        pendingResponse.success(pendingResponse.keys);
      });
      self._notifications.pendingKeyRequests = [];
      return {
        state: COMPLETED_STATE,
        error: undefined,
      };
    } catch (error) {
      self._isRefreshing = false;
      self._notifications.pendingKeyRequests.forEach((pendingResponse) => {
        pendingResponse.error(new ResponseError(pendingResponse.keys, error));
      });
      self._notifications.pendingKeyRequests = [];
      return {
        state: FAILED_STATE,
        error: new AxiosMessage(error),
      };
    }
  }

  /**
   *
   * @param {'string' | Array[string]} dataKeys
   */
  valuesForKeys(dataKeys) {
    const keys = Array.isArray(dataKeys) ? dataKeys : [dataKeys];
    const self = this;
    return new Promise((resolve, reject) => {
      const handler = self._createPendingResponse(keys);
      const fulfillment = handler(resolve, reject);

      if (self._isRefreshing || !self._data) {
        // Store the logic until the refresh has completed.
        self._notifications.pendingKeyRequests.push(fulfillment);
        self.refresh();
      } else {
        fulfillment.success(keys);
      }
    });
  }

  async save(object) {
    const self = this;
    const serverModel = this._prepareDataToSave(object);
    return new Promise((resolve, reject) => {
      appAxios.post(self._path, { ...serverModel })
        .then((response) => {
          const { data: body } = response;
          self._onSaveSuccess(serverModel, body);
          self._sendUpdates(Object.keys(serverModel));
          resolve(response.data);
        })
        .catch((error) => {
          reject(new ResponseError({ data: object }, error));
        });
    });
  }

  // Store the logic until the refresh has completed.
  // Subclass has the choice to alter the response to the caller.
  // (e.g. Convert success to error and vice versa, depending on their processing)
  _createPendingResponse(keysRequested) {
    const self = this;
    const promiseHandler = function (resolve, reject) {
      return {
        keys: keysRequested,
        success(keys) {
          self._onKeysRequestSuccess(
            keys,
            (data) => { resolve(data); },
            (error) => { reject(error); },
          );
        },
        error(responseError) {
          self._onKeysRequestError(
            responseError,
            (error) => { reject(error); },
            (data) => { resolve(data); },
          );
        },
      };
    };
    return promiseHandler;
  }

  /**
   * Assume the caller wants notification when any or all of the
   * keys are updated.
   * Handles bulk change by storing given keys as an ordered stringyfied key entry.
   * (lazy implementation). Also store each key to the callback.
   * @param {*} keys
   * @param {*} callback
   */
  receiveUpdates(keys, callback) {
    keys = keys || API_CACHE_ALL_KEYS;
    keys = Array.isArray(keys) ? keys : [keys];
    const copy = [...keys];

    // Avoid double entry: [1].toString() === "1"
    if (copy.length > 1) {
      copy.sort();
      copy.push(copy.toString());
    }

    const self = this;
    copy.forEach((key) => {
      self._notifications.keyListeners[key] = self._notifications.keyListeners[key] || [];
      self._notifications.keyListeners[key].push(callback);
    });
  }

  cancelUpdates(keys, callback) {
    keys = keys || [API_CACHE_ALL_KEYS];
    keys = Array.isArray(keys) ? keys : [keys];
    const copy = [...keys];

    // Avoid double entry: [1].toString() === "1"
    if (copy.length > 1) {
      copy.sort();
      copy.push(keys.toString());
    }

    const self = this;
    copy.forEach((key) => {
      self._notifications.keyListeners[key] = self._notifications.keyListeners[key] || [];
      const index = self._notifications.keyListeners[key].indexOf(callback);
      if (index >= 0) {
        self._notifications.keyListeners[key].splice(index, 1);
      }
    });
  }

  /**
   * Call subclass with all callbacks registered for givens keys.
   * Those that registered for multiple keys will receive one callback
   * with all keys, instead of each separately.
   * The UI can't handle successive individual callbacks as they are
   * out of sync with React 'state' and each receipt is treated as 'all' data.
   * @param {} keys
   */
  _sendUpdates(keys) {
    keys = Array.isArray(keys) ? keys : [keys];
    const copy = [...keys];
    const self = this;
    const receivers = []; // track served callbacks

    // Avoid double entry: [1].toString() === "1"
    if (copy.length > 1) {
      copy.sort();
      const multiKey = copy.toString();
      self._notifications.keyListeners[multiKey] = self._notifications.keyListeners[multiKey] || [];
      self._notifications.keyListeners[multiKey].forEach((callback) => {
        self._onKeysUpdated(copy, callback);
        receivers.push(callback);
      });
    }

    copy.forEach((key) => {
      self._notifications.keyListeners[key] = self._notifications.keyListeners[key] || [];
      self._notifications.keyListeners[key].forEach((callback) => {
        // Don't resend if callback received mutlikey update.
        if (receivers.indexOf(callback) < 0) {
          self._onKeysUpdated(key, callback);
        }
      });

      self._notifications.keyListeners[API_CACHE_ALL_KEYS] = self._notifications.keyListeners[API_CACHE_ALL_KEYS] || [];
      self._notifications.keyListeners[API_CACHE_ALL_KEYS].forEach((callback) => {
        if (receivers.indexOf(callback) < 0) {
          self._onKeysUpdated(key, callback);
        }
      });
    });
  }

  _onDataChange(data) {
    throw new Error('Subclass must override _onDataChange()');
  }

  _onKeysRequestSuccess(keys, successFunc, errorFunc) {
    throw new Error('Subclass must override _onKeysRequestSuccess()');
  }

  _onKeysRequestError(errorResponse, errorFunc, successFunc) {
    throw new Error('Subclass must override _onKeysRequestError()');
  }

  _onKeysUpdated(keys, callback) {
    throw new Error('Subclass must override _onKeysUpdated()');
  }

  _onSaveSuccess(data, serverMessage) {
    throw new Error('Subclass must override _onSaveSuccess()');
  }

  _prepareDataToSave(data) {
    return data;
  }
}

export class ResponseError {
  constructor(data, axiosError, overrideMessage) {
    this.data = data;
    this.message = overrideMessage;
    if (axiosError) {
      this.message = new AxiosMessage(axiosError);
    }
  }

  /**
   * Extracts the error message from an error object.
   * Expects either a system Error or ResponseError.
   * @param {*} error ResponseError | Error | string
   * @param {*} options Preferences: { network: true | false, server: true | false}
   */
  static message(error, options) {
    if (!error) {
      return '';
    }

    let message = '';
    if (error.constructor.name === 'ResponseError') {
      message = error.message.serverMessage || error.message.networkMessage;
      if (options && options.network) {
        message = error.message.networkMessage || error.message.serverMessage;
      }
    }
    // AxiosMessage or some equivalent object.
    // (Appears that CRA app build obfuscates constructors.)
    else if (error.constructor.name === 'AxiosMessage' || (
      Object.prototype.hasOwnProperty.call(error, 'serverMessage')
      && Object.prototype.hasOwnProperty.call(error, 'networkMessage'))
    ) {
      message = error.serverMessage || error.networkMessage;
      if (options && options.network) {
        message = error.networkMessage || error.serverMessage;
      }
    } else if (typeof error === 'string') {
      message = error;
    } else if (error.constructor.name === 'Error' || Object.prototype.hasOwnProperty.call(error, 'message')) {
      message = error.message;
    }
    return message;
  }
}


export class AxiosMessage {
  constructor(axiosError) {
    this.axiosError = axiosError;
    this.networkMessage = AxiosMessage.networkMessage(axiosError);
    this.serverMessage = AxiosMessage.serverMessage(axiosError);
    this.statusCode = AxiosMessage.networkStatus(axiosError);
  }

  static networkMessage(axiosError) {
    return axiosError.message;
  }

  static serverMessage(axiosError) {
    let message = '';
    if (axiosError.response) {
      if (axiosError.response.data && axiosError.response.data.message) {
        message = axiosError.response.data.message;
      } else {
        message = axiosError.response.statusText;
      }
    }
    return message;
  }

  static networkStatus(axiosError) {
    let statusCode = 0;
    if (axiosError.response) {
      statusCode = axiosError.response.status;
    }
    return statusCode;
  }
}
