import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { convertColumnNamesToProperties as convertColumns, createRow, emptyRow } from '../../helpers/table-component-data';
import { makeCancelable } from '../../helpers/cancellable-promise';
import { hasValuesForKeys } from '../../helpers/object';
import metricsDataAPI from '../../data/metrics-data-api';
import SearchableTable from '../../modified-unity-library-components/table-with-search';
import { ResponseError } from '../../data/api-cache';

// UI Namaes
const ColNameMetric = 'Metric';
const ColNameAssetCache = 'Asset Import Pipeline V2';
const ColNameCollabCache = 'Collab';
const ColNameValue = 'Value';
const columnHeaders = [ColNameMetric, ColNameValue];
const sampledMetricsHeaders = convertColumns(columnHeaders);

const RowNameAccelInfo = 'Accelerator Version';
const RowNameDiskUtil = 'Disk Utilization';
const RowNameCPUUtil = 'CPU Utilization';
const RowNameUtilEffeciency = 'Requests Served from cache (effeciency)';

const displayedMetrics = [
  RowNameAccelInfo,
  RowNameDiskUtil,
  RowNameCPUUtil,
//   RowNameUtilEffeciency
];

// Metric Names.
const uta_agent_info = 'uta_agent_info';
const uta_agent_sys_disk_bytes_used = 'uta_agent_sys_disk_bytes_used';
const uta_agent_sys_disk_bytes_total = 'uta_agent_sys_disk_bytes_total';
const uta_agent_cache_bytes_stored = 'uta_agent_cache_bytes_stored';
const uta_agent_sys_cpu_percent = 'uta_agent_sys_cpu_percent';
const uta_agent_sys_cpu_process_percent = 'uta_agent_sys_cpu_process_percent';
const uta_agent_download_efficiency = 'uta_agent_download_efficiency';


const MetricsUtilizationTable = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(_createRows()); // Load all rows with empty default state.

  function onMetricsLoaded(data) {
    setRows(_createRows(data));
    setIsLoading(false);
  }

  function onMetricsFailed(errorMessage) {
    setIsLoading(false);
  }

  function _createRows(metrics) {
    const _rows = [];
    displayedMetrics.forEach((name) => {
      _rows.push(_rowForName(name, metrics));
    });
    return _rows;
  }

  function _rowForName(rowName, metrics) {
    let row = null;
    switch (rowName) {
      case RowNameAccelInfo: {
        row = _createAcceleratorInfoRow(metrics);
        break;
      }
      case RowNameDiskUtil: {
        row = _createDiskUtilizationRow(metrics);
        break;
      }
      case RowNameCPUUtil: {
        row = _createCPUtilizationRow(metrics);
        break;
      }
      case RowNameUtilEffeciency: {
        row = _createEffeciencyRow(metrics);
        break;
      }
      default: {
        row = emptyRow();
      }
    }
    row[ColNameMetric] = rowName;
    return row;
  }

  function _createAcceleratorInfoRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_info])) {
      return emptyRow(columnHeaders);
    }
    const agentInfo = metrics[uta_agent_info][0].labels.version;
    return createRow(columnHeaders, ['', agentInfo]);
  }

  function _createDiskUtilizationRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_sys_disk_bytes_used, uta_agent_sys_disk_bytes_total, uta_agent_cache_bytes_stored])) {
      return emptyRow(columnHeaders);
    }
    const bytesUsed = metrics[uta_agent_sys_disk_bytes_used];
    const bytesTotal = metrics[uta_agent_sys_disk_bytes_total];
    const bytesCached = metrics[uta_agent_cache_bytes_stored];
    const ratio = numeral(bytesUsed / bytesTotal);
    const percentage = ratio.format('0%');
    const cachedRatio = numeral(bytesCached / bytesTotal);
    const cachedPercentage = cachedRatio.format('0%');
    return createRow(columnHeaders, ['', `${percentage} (${cachedPercentage} by accelerator)`]);
  }

  function _createCPUtilizationRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_sys_cpu_percent, uta_agent_sys_cpu_process_percent])) {
      return emptyRow(columnHeaders);
    }
    const overallRatio = numeral(metrics[uta_agent_sys_cpu_percent]);
    const overallStrippedDecimalPlaces = overallRatio.format('0[.]00');
    const acceleratorRation = numeral(metrics[uta_agent_sys_cpu_process_percent]);
    const acceleratorStrippedDecimalPlaces = acceleratorRation.format('0[.]00');
    return createRow(columnHeaders, ['', `${overallStrippedDecimalPlaces}% (${acceleratorStrippedDecimalPlaces}% by accelerator)`]);
  }

  function _createEffeciencyRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_download_efficiency])) {
      return emptyRow(columnHeaders);
    }
    const ratio = numeral(metrics[uta_agent_download_efficiency]);
    const percentage = ratio.format('0%');
    return createRow(columnHeaders, ['', percentage]);
  }

  // Data loaded.
  useEffect(() => {
    const metrics = [
      uta_agent_info,
      uta_agent_sys_disk_bytes_used,
      uta_agent_sys_disk_bytes_total,
      uta_agent_cache_bytes_stored,
      uta_agent_sys_cpu_percent,
      uta_agent_sys_cpu_process_percent,
      uta_agent_download_efficiency,
    ];
    let fetchRequest;
    (async function () {
      try {
        fetchRequest = makeCancelable(metricsDataAPI.valuesForKeys(metrics));
        const results = await fetchRequest.promise;
        onMetricsLoaded(results);
      } catch (error) {
        const message = ResponseError.message(error);
        onMetricsFailed(message);
      }
    }());

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
    };
  }, []);


  return (
    <div>
      <h3>Collected at { new Date().toLocaleTimeString() }</h3>
      <SearchableTable rows={rows} columns={sampledMetricsHeaders} isLoading={isLoading} />
    </div>
  );
};

export default (MetricsUtilizationTable);
