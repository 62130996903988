
/**
 * Generates an array of objects formatted to SmartTable's column property.
 * Given column names will act as keys/"properties" to reference columns
 * (assumed to be unique)
 * @param {array of strings} columnNames
 */
export function convertColumnNamesToProperties(columnNames) {
  if (!columnNames || columnNames.length === 0) {
    return [];
  }

  const properties = [];
  columnNames.forEach((name) => {
    const key = removeWhiteSpaceBetween(name);
    const obj = {
      property: key,
      header: {
        label: name,
      },
    };
    properties.push(obj);
  });
  return properties;
}

/**
 * Creates an object with keys derived from column names with
 * values of the same index in 'rowData
 * @param {Array - string} columnNames
 * @param {Array} rowData
 * @returns Object whose keys are column string without whitespace.
 */
export const createRow = (columnNames, rowData) => {
  if (!columnNames || columnNames.length === 0 || !rowData || rowData.length === 0) {
    return [];
  }

  const row = {};
  for (let i = 0; i < rowData.length; i++) {
    const key = removeWhiteSpaceBetween(columnNames[i]);
    row[key] = rowData[i];
  }
  return row;
};

/**
 *
 * @param {Array - string} columns UI display column header text.
 */
export const emptyRow = (columns) => {
  const rowData = [];
  columns.forEach((item) => {
    rowData.push('-');
  });
  return createRow(columns, rowData);
};

function removeWhiteSpaceBetween(str) {
  return str.replace(/ +/g, '');
}
