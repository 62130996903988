import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@unity/react-components/typography';

import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';

/**
 *
 * @param {*} props
 * label {string}
 * value {string}
 * actionElement {element}
 */
const RowLayout = (props) => {
  const { classes } = props;
  function columnOneContent() {
    return (
      <Typography variant="body" className={classes.columnOne} component="div">
        {props.label}
      </Typography>
    );
  }

  /**
  * Accept string or element.
  * Put string in element.
  */
  function columnTwoContent() {
    if (props.isLoading) {
      return (<SkeletonLoader width={400} height={20} rounded />);
    }

    if (!props.value) {
      return null;
    }

    if (typeof props.value === 'string' || props.value instanceof String) {
      return (
        <Typography variant="bodyLight" className={classes.columnTwo} wordBreak="all" component="div">
          {props.value}
        </Typography>
      );
    }
    return (<div className={classes.columnTwo}>{props.value}</div>);
  }

  function columnThreeContent() {
    if (props.actionElement) {
      return (<div className={classes.columnThree}>{props.actionElement}</div>);
    }
    return null;
  }

  return (
    <div className={classes.root}>
      {columnOneContent()}
      {columnTwoContent()}
      {columnThreeContent()}
    </div>
  );
};

const styles = ({ spacing, palette }) => ({
  root: {
    padding: spacing(0, 3, 0, 4),
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: `1px solid ${palette.divider}`,
    minHeight: 48,
    flexGrow: 1,
  },
  columnOne: {
    display: 'flex',
    flexBasis: '41%',
    flexGrow: 0,
    flexShrink: 0,
  },
  columnTwo: {
    display: 'flex',
    flexGrow: 1,
  },
  columnThree: {
    display: 'flex',
  },
});

RowLayout.propTypes = {
  isLoading: PropTypes.bool,
};

RowLayout.defaultProps = {
  isLoading: false,
};

export default withStyles(styles)(RowLayout);
