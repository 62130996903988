import React from 'react';
import PageHeader from '@unity/react-components/page-header/page-header';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Redirect} from "react-router-dom";
import {makeCancelable} from "../helpers/cancellable-promise";
import appAxios from "../data/app-axios";

const accountsEndpointPath = "/api/accounts";
const metricsPageEndpoint = "/metrics-page/";

class HomePage extends React.Component {
  state = { redirect: null };

  render() {
    let accountsRequestPromise = makeCancelable(appAxios.get(accountsEndpointPath));

    accountsRequestPromise.promise.then((response) => {
      // When the list of accounts is empty, this means dashboard is not using local account for auth.
      if (response.status !== 204) {
        this.setState((prevState) => ({...prevState, redirect: metricsPageEndpoint}));
      }
    }).catch((error) => {
      console.log(error)
    });

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }

    return (
      <div>
        <PageHeader title="Dashboard" />
        <Card>
          <CardContent>
            We have recently changed the way authentication is handled for dashboard.<br/>
            <ul>
              <li>If you already set up a password, you can still continue using the same password without a username until you use the updated command line tool <b>unity-accelerator dashboard password</b>.</li>
              <li><b>unity-accelerator dashboard password</b> accepts an account name and this lets you set up multiple logins for the dashboard.</li>
              <li>Authentication information is stored in the storage directory at <b>unity-accelerator-dashboard.json</b>.</li>
            </ul>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default HomePage;
