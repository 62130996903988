/**
 * Returns the port specified in window.location as integer.  If not
 * specified, determines port by protocol.
 * @returns {number}
 */
export function locationPort() {
  let { port } = location;
  if (!port) {
    port = 80;
    if (location.protocol === 'https:') {
      port = 443;
    }
  } else {
    port = parseInt(port);
  }
  return port;
}
