import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@unity/react-components/typography';
import ErrorIcon from '@material-ui/icons/Error';

/**
 * Displays an material edit icon with a line of text folling in-line.
 * e.g.  [icon] Followed by this sentence
 * @param {*} props
 */
const ErrorText = (props) => (
  <div className={props.classes.modalErrorContainer}>
    <ErrorIcon color="error" className={props.classes.modalErrorIcon} />
    <Typography variant="bodyLight" className={props.classes.modalErrorTypographyOverride}>
      <i>{props.text}</i>
    </Typography>
  </div>
);

ErrorText.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
};

ErrorText.defaultProps = {
  text: '',
};

const styles = () => ({
  modalErrorContainer: {
    display: 'flex',
  },
  modalErrorIcon: {
    marginRight: 14,
  },
  modalErrorTypographyOverride: {
    paddingTop: 2, // Otherwise doesn't mid-align with icon.
  },
});

export default withStyles(styles)(ErrorText);
