import APICache from './api-cache';
import restartMonitor from './restart-monitor';
import { translatorForKey } from './data-translators';

/**
 * Adds data translators to data responses to the caller.
 * These can optionally be used transform the data to and from UI needs.
 */
class ConfigurationDataCache extends APICache {
  constructor() {
    super('/api/config');
  }

  _onDataChange(data) {
    this._data = data;
    restartMonitor.checkRestartNeeded();
  }

  /**
   *
   *  key: {
   *    data:
   *    translators: {
   *      toDisplay:
   *      toServerModel:
   *      toViewModel:
   *    }
   *  }
   */
  _buildDataObject(keys) {
    keys = Array.isArray(keys) ? keys : [keys];
    const obj = {};
    const self = this;
    keys.forEach((key) => {
      obj[key] = {};
      const translator = translatorForKey(key);
      const data = self._data[key];
      obj[key].data = data;
      obj[key].toDisplay = function () { return translator.constructor.toDisplay(this.data, ...arguments); };
      obj[key].toViewModel = function () { return translator.constructor.toViewModel(this.data, ...arguments); };
      obj[key].toServerModel = function () { return translator.constructor.toServerModel(this.data, ...arguments); };
    });
    return obj;
  }

  /**
   *
   * @param {*} configItem Single-keyed object or Config ID (key-only);
   */
  embedTranslators(configItem) {
    if (typeof configItem === 'string') {
      // Assume a config ID was given.
      return this._buildDataObject(configItem);
    }
    const key = Object.keys(configItem)[0];
    const obj = this._buildDataObject(key);
    obj[key].data = configItem[key];
    return obj;
  }

  /**
   * @param {*} keys
   * @param {*} succesFunc
   * @param {*} errorFunc
   */
  _onKeysRequestSuccess(keys, succesFunc, errorFunc) {
    const obj = this._buildDataObject(keys);
    succesFunc(obj);
  }

  _onKeysRequestError(errorResponse, errorFunc, successFunc) {
    errorFunc(errorResponse);
  }

  _onKeysUpdated(keys, callback) {
    const obj = this._buildDataObject(keys);
    callback(obj);
  }

  _prepareDataToSave(data) {
    const serverModel = {};
    const self = this;
    Object.keys(data).forEach((key) => {
      const obj = self.embedTranslators({ [key]: data[key] });
      serverModel[key] = obj[key].toServerModel();
    });
    return serverModel;
  }

  _onSaveSuccess(data, serverMessage) {
    const self = this;
    Object.keys(data).forEach((key) => {
      self._data[key] = data[key];
    });

    // Ensure data is updated before calling.
    // (code is async. Other events can/will read old data)
    restartMonitor.setRestartNeeded(serverMessage.restartRequired, serverMessage.restartReasons);
  }
}

const configurationDataAPI = new ConfigurationDataCache();
export default configurationDataAPI;
