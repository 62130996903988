export const WRITE_STATE = 'WRITE_STATE';
export const READONLY_STATE = 'READONLY_STATE';
export const EMPTY_STATE = 'EMPTY_STATE';

export const IN_PROGRESS_STATE = 'IN_PROGRESS_STATE';
export const COMPLETED_STATE = 'COMPLETED_STATE';
export const FAILED_STATE = 'FAILED_STATE';

export const BUTTONS_SAVE = 'BUTTONS_SAVE';
export const BUTTONS_CLOSE = 'BUTTONS_CLOSE';

export const INTERNAL = 'INTERNAL';
export const EXTERNAL = 'EXTERNAL';

export const HOUR = 'hour';
export const HOURS = 'hours';
export const MINUTE = 'minute';
export const MINUTES = 'minutes';
export const AM_TIME = 'a.m.';
export const PM_TIME = 'p.m.';
export const MERIDIEM = 'meridiem';

export const METRIC_KEY_DISK_TOTAL = 'uta_agent_sys_disk_bytes_total';
export const METRIC_KEY_SYSTEM_BYTES_USED = 'uta_agent_sys_disk_bytes_used';
export const METRIC_KEY_DISK_USED_BY_AGENT = 'go_memstats_heap_alloc_bytes';
