import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


/**
 * Extends Materials's TextField by adding a generic callback to match
 * the 'onChange' interface to other web controls.
 *
 * 'OnChange' returns the assigned data.
 * @param {*} props
 */
const TextInput = (props) => {
  const [text, setText] = useState(props.text);

  function _onUserInput({ target: { value } }) {
    setText(value);
    if (props.onChange) {
      props.onChange(value);
    }
  }

  return (
    <TextField
      type="search"
      autoFocus
      value={text}
      helperText=""
      onChange={_onUserInput}
      className={props.classes.textFieldOverride}
    />
  );
};

const styles = () => ({
  textFieldOverride: {
    width: '100%',
  },
});

TextInput.propTypes = {
  onChange: PropTypes.func,
};

export default withStyles(styles)(TextInput);
