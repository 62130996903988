import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withOpenability, { openableShape } from '@unity/react-components/hoc/with-openability';
import { withStyles } from '@material-ui/core';
import flow from 'lodash/flow';
import DescriptionCard from '@unity/react-components/description-card';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ExpandingTreeDataHandler } from '../../helpers/expandingtree-datahandler';


import ConfigRowWithModal from './config-row';
import IconOnChangeButton from '../on-change-iconbutton';


const ExpandableList = (props) => {
  const [tree, setTree] = useState(null);
  const [treeRoot, setTreeRoot] = useState(null);
  const [collapseState, setCollapseState] = useState({});

  const handleExpandClick = (node) => {
    if (node.isOpen) {
      tree.closeNode(node);
    } else {
      tree.openNode(node);
    }
    setCollapseState((prevState) => ({ ...prevState, [node.ID]: node.isOpen }));
  };

  function rowUI(rowData) {
    let modalContent = null;
    if (rowData.modal) {
      modalContent = (props) => <rowData.modal {...props} />;
    }

    let valueElement = null;
    if (rowData.element) {
      valueElement = (props) => <rowData.element {...props} />;
    }

    return (
      <ConfigRowWithModal
        title={rowData.title}
        dataID={rowData.key}
        valueElement={valueElement}
        key={rowData.key}
        canEdit={rowData.canEdit}
        elementTriggersModal={rowData.elementTriggerModal === true}
        modalContent={modalContent}
      />
    );
  }

  function headerRowUI(node) {
    const rowData = node.content;
    let valueElement = null;
    if (rowData.element) {
      valueElement = (props) => <rowData.element {...props} />;
    }

    const CSS = node.isOpen ? props.classes.headerIconRotated : props.classes.headerIconDefault;
    const action = (
      <IconOnChangeButton onChange={() => handleExpandClick(node)}>
        <KeyboardArrowRightIcon className={CSS} />
      </IconOnChangeButton>
    );

    return (
      <ConfigRowWithModal
        title={rowData.title}
        dataID={rowData.key}
        valueElement={valueElement}
        key={rowData.key}
        actionElement={action}
      />
    );
  }

  useEffect(() => {
    if (!tree) {
      const _tree = new ExpandingTreeDataHandler();
      _tree.loadFromNestedArrays(props.data.rows, 'rows', 'key');
      const rootNode = _tree.nodeForID(_tree.defaultRootID());
      setTree(_tree);
      setTreeRoot(rootNode);
    }

    return function cleanup() {
    };
  }, []);

  function rowsUI(node) {
    if (!node) {
      return null;
    }

    if (node === treeRoot) {
      return (
        <Fragment key={`fragment for ${node.ID}`}>
          {node.children.map((childNode) => rowsUI(childNode))}
        </Fragment>
      );
    }

    if (node.isLeaf()) {
      return rowUI(node.content);
    }

    // Return Header and Children.
    return (
      <Fragment key={`fragment for ${node.ID}`}>
        {headerRowUI(node)}
        <Collapse in={collapseState[node.ID]} timeout="auto" key={`collapse for ${node.ID}`}>
          {node.children.map((childNode) => rowsUI(childNode))}
        </Collapse>
      </Fragment>
    );
  }

  const { classes, data } = props;
  const { listHeader } = data;
  return (
    <>
      <DescriptionCard
        title={listHeader.title}
        description={listHeader.description}
        className={classes.cardOverride}
        key={listHeader.id}
      >
        {rowsUI(treeRoot)}
      </DescriptionCard>
    </>
  );
};

const styles = () => ({
  cardOverride: {
    maxWidth: '9000px',
    '@media (max-width: 959.95px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  headerIconRotated: {
    transform: 'rotate(90deg)',
    webkitTransform: 'rotate(90deg)',
    mozTransform: 'rotate(90deg)',
    otransform: 'rotate(90deg)',
    msTransform: 'rotate(90deg)',
    animation: '$rotate-open 0.15s linear',
  },
  headerIconDefault: {
    transform: 'rotate(0deg)',
    webkitTransform: 'rotate(0deg)',
    mozTransform: 'rotate(0deg)',
    otransform: 'rotate(0deg)',
    msTransform: 'rotate(0deg)',
    animation: '$rotate-closed 0.15s linear',
  },

  '@keyframes rotate-open': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(90deg)' },
  },
  '@keyframes rotate-closed': {
    from: { transform: 'rotate(90deg)' },
    to: { transform: 'rotate(0deg)' },
  },
});

ExpandableList.propTypes = {
  dialogOpenable: openableShape.isRequired,
  //   data: PropTypes.array.isRequired, <= data is an object {}
  onRowEditListener: PropTypes.func,
  // modal: PropTypes.element.isRequired,
};

export default flow(withStyles(styles), withOpenability('dialogOpenable'))(ExpandableList);
