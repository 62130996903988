
// String code. "86d7bb3c-43fb-4fb3-b91e-860dba48545f
export const AgentID = 'AgentID';

// String. "My local machine"
export const AgentName = 'AgentName';

// string. "production"
export const EnvironmentType = 'EnvironmentType';

// String. IP Address. e.g. "10.1.162.115"
export const IP = 'IP';

// String. IP Address. e.g. "10.1.162.115"
export const LastUsedIP = 'LastUsedIP';

// Number. e.g. 0
export const HTTPPort = 'HTTPPort';

// Number. e.g. 443
export const LastUsedHTTPPort = 'LastUsedHTTPPort';

// Number. e.g. 0
export const ProtobufPort = 'ProtobufPort';

// Number. e.g. 10080
export const LastUsedProtobufPort = 'LastUsedProtobufPort';

// String long.
export const AgentAuth = 'AgentAuth';

// String. Long.
export const JWTPublicKey = 'JWTPublicKey';

// String
export const JWTPublicKeyFile = 'JWTPublicKeyFile';

// String of number. "2473922259255"
export const OrgID = 'OrgID';

// String. host path. e.g. "electrical-apparatus-8420.accelerator-7.unity3dusercontent.com"
export const AgentHostName = 'AgentHostName';

// String path.
export const CertificateURL = 'CertificateURL';

// String. Long.
export const CertificatePrivateKey = 'CertificatePrivateKey';

// String
export const CertPEM = 'CertPEM';

// String
export const KeyPEM = 'KeyPEM';

// Duration: minutes, seconds. e.g. "10m0s"
export const MetricReportInterval = 'MetricReportInterval';

// Duration: hours, minutes, seconds. e.g. "24h0m0s"
export const ConfigQueryInterval = 'ConfigQueryInterval';

// Duration: minutes, seconds. e.g. "1m0s"
export const MetricsUpdateInterval = 'MetricsUpdateInterval';

// Clock time. e.g. "01:00"
export const MaintenanceStart = 'MaintenanceStart';

// Duration. Hours, minutes, seconds. e.g. "1h0m0s"
export const MaintenanceDuration = 'MaintenanceDuration';

// Boolean
export const Debug = 'Debug';

// Count. e.g. 100
export const LogUploadEventsBuffer = 'LogUploadEventsBuffer';

// String. Path.  e.g. "/Applications/Unity/accelerator"
export const InstallDir = 'InstallDir';

// Boolean
export const NoAutoUpdates = 'NoAutoUpdates';

// Boolean
export const CollabEnabled = 'CollabEnabled';

// Boolean
export const ADBV2Enabled = 'ADBV2Enabled';

// Boolean
export const LegacyEnabled = 'LegacyEnabled';

// String. system path. e.g. "/Users/eli/Library/Unity/accelerator"
export const CacheDir = 'CacheDir';

// Count. e.g. 2
export const CacheDirSplit = 'CacheDirSplit';

// Duration. Minutes/Seconds. e.g. "1m0s"
export const CacheMonitorInterval = 'CacheMonitorInterval';

// Bytes e.g. 1073741824 (-1 if no set)
export const CacheMinFreeBytes = 'CacheMinFreeBytes';

// Integer e.g. 5 (-1 if no set)
export const CacheMinFreePercent = 'CacheMinFreePercent';

// Bytes e.g. 1073741824 (-1 if no set)
export const CacheMaxUsedBytes = 'CacheMaxUsedBytes';

// Integer e.g. 5 (-1 if no set)
export const CacheMaxUsedPercent = 'CacheMaxUsedPercent';

// Bytes e.g. 5368709120 (-1 if no set)
export const CacheEvictUntilFreeBytes = 'CacheEvictUntilFreeBytes';

// Count e.g. 10 (-1 if no set)
export const CacheEvictUntilFreePercent = 'CacheEvictUntilFreePercent';

// Bytes e.g. 5368709120 (-1 if no set)
export const CacheEvictUntilUsedBytes = 'CacheEvictUntilUsedBytes';

// Count e.g. 10 (-1 if no set)
export const CacheEvictUntilUsedPercent = 'CacheEvictUntilUsedPercent';

// Integer e.g. 10000
export const PrefetchMaxPending = 'PrefetchMaxPending';

// Integer e.g. 4
export const PrefetchConcurrency = 'PrefetchConcurrency';

// Duration. Seconds. e.g. "10s"
export const DefaultHTTPTimeout = 'DefaultHTTPTimeout';

// Duration. Seconds. e.g. "10s"
export const DefaultHTTPKeepAlive = 'DefaultHTTPKeepAlive';

// Boolean
export const DefaultHTTPInsecureSkipVerify = 'DefaultHTTPInsecureSkipVerify';

// Boolean
export const DefaultHTTPAutoEnableHTTP2 = 'DefaultHTTPAutoEnableHTTP2';

// Integer e.g. 5
export const DefaultHTTPAttempts = 'DefaultHTTPAttempts';

// Seconds "1s"
export const DefaultHTTPDelay = 'DefaultHTTPDelay';

// Bytes. e.g. 65536
export const DefaultHTTPChunkSize = 'DefaultHTTPChunkSize';

// String. e.g. "https://collab-accelerator.cloud.unity3d.com"
export const UTAServiceURL = 'UTAServiceURL';

// Seconds "10s"
export const UTAServiceTimeout = 'UTAServiceTimeout';

// Seconds e.g. "10s"
export const UTAServiceKeepAlive = 'UTAServiceKeepAlive';

// Boolean
export const UTAServiceInsecureSkipVerify = 'UTAServiceInsecureSkipVerify';

// Boolean.
export const UTAServiceAutoEnableHTTP2 = 'UTAServiceAutoEnableHTTP2';

// Integer e.g. 5
export const UTAServiceAttempts = 'UTAServiceAttempts';

// Duration. Seconds e.g. "1s"
export const UTAServiceDelay = 'UTAServiceDelay';

// Bytes. e.g. 65536
export const UTAServiceChunkSize = 'UTAServiceChunkSize';

// String. e.g. "https://collab.cloud.unity3d.com"
export const CollabURL = 'CollabURL';

// Duration. Minutes, Seconds. e.g. "10m0s"
export const CollabTimeout = 'CollabTimeout';

// Duration. Seconds, e.g. "10s"
export const CollabKeepAlive = 'CollabKeepAlive';

// Boolean
export const CollabInsecureSkipVerify = 'CollabInsecureSkipVerify';

// Boolean
export const CollabAutoEnableHTTP2 = 'CollabAutoEnableHTTP2';

// Integer
export const CollabAttempts = 'CollabAttempts';

// Seconds
export const CollabDelay = 'CollabDelay';

// Bytes (integer)
export const CollabChunkSize = 'CollabChunkSize';

// Integer
export const ProtobufPerStreamBuffer = 'ProtobufPerStreamBuffer';

// Boolean
export const ProtobufTLSRequired = 'ProtobufTLSRequired';

// Boolean
export const ProtobufAuthRequired = 'ProtobufAuthRequired';
