import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeCancelable } from '../../helpers/cancellable-promise';
import configurationDataAPI from '../../data/configuration-data-api';
import { ResponseError } from '../../data/api-cache';

const ConfigSwitch = (props) => {
  const [isOn, setIsOn] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [saveData, setSaveData] = useState(null);

  function onToggle(event, value) {
    if (isSaving) {
      return;
    }

    setIsOn(value);
    if (props.onChange) {
      props.onChange(value);
    }
    setSaveData(value);
  }

  useEffect(() => {
    if (saveData === null) {
      return;
    }

    setIsSaving(true);

    let saveRequest;
    (async function () {
      try {
        saveRequest = makeCancelable(configurationDataAPI.save({ [props.dataID]: saveData }));
        const data = await saveRequest.promise;
        onSaveComplete(data);
        setSaveData(null);
      } catch (error) {
        const message = ResponseError.message(error);
        onSaveError(error.data[props.dataID], message);
      }
    }());

    return function cleanup() {
      if (saveRequest) {
        saveRequest.cancel();
      }
    };
  }, [saveData]);

  function onSaveComplete(data) {
    setShowError(false);
    setIsSaving(false);
  }

  function onSaveError(unSavedData, message) {
    const longMessage = `Failed saving '${unSavedData[props.dataID]}'. Give it another shot. ${message}`;
    setErrorMessage(longMessage);
    setShowError(true);
    setIsSaving(false);
    setIsOn(!unSavedData[props.dataID]);
  }

  function onErrorClickOff() {
    setShowError(false);
  }

  // 'listen' for changes to props otherwise useState does
  // not correctly update one first interaction after agent restart.
  useEffect(() => {
    setIsOn(props.data);
  }, [props.data]);

  const iconCSS = isOn ? props.classes.loadingIconOn : props.classes.loadingIconOff;
  let switchCSS = props.classes.switchDefault;
  if (isSaving) {
    if (isOn) {
      switchCSS = props.classes.switchSavingOn;
    } else {
      switchCSS = props.classes.switchSavingOff;
    }
  }

  return (
    <div className={props.classes.container}>
      <Fade in={isSaving}>
        <CircularProgress className={iconCSS} size={20} />
      </Fade>
      <Switch
        className={switchCSS}
        checked={isOn}
        color="primary"
        onChange={onToggle}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showError}
        onClose={onErrorClickOff}
      >
        <SnackbarContent
          className={props.classes.snackbarContent}
          message={(
            <span id="message-id" className={props.classes.snackbarMessage}>
              <ErrorIcon className={clsx(props.classes.snackbarIcon, props.classes.icons)} />{errorMessage}
            </span>
)}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={onErrorClickOff}>
              <CloseIcon className={props.classes.icons} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
};

const styles = ({ spacing, palette }) => ({
  container: {
    position: 'relative%',
  },
  loadingIconOn: {
    position: 'absolute',
    marginLeft: 28,
    marginTop: 9,
  },
  loadingIconOff: {
    position: 'absolute',
    marginLeft: 9,
    marginTop: 9,
  },
  switchSavingOn: {
    opacity: 0.2,
  },
  switchSavingOff: {
    opacity: 0.8,
  },
  switchDefault: {
    opacity: 1,
  },
  snackbarContent: {
    backgroundColor: palette.error.dark,
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcon: {
    opacity: 0.9,
    marginRight: spacing(1),
    fontSize: 20,
  },

});

ConfigSwitch.propTypes = {
  onChange: PropTypes.func,
  dataID: PropTypes.string,
  data: PropTypes.bool,
};

ConfigSwitch.defaultProps = {
  data: false,
};

export default withStyles(styles)(ConfigSwitch);
