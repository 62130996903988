import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import IconButton from '@unity/react-components/icon-button';
import EditIcon from '@material-ui/icons/Edit';
import configurationDataAPI from '../../data/configuration-data-api';
import { makeCancelable } from '../../helpers/cancellable-promise';
import RowLayout from '../../app-wide-controls/row-layout';
import ErrorText from '../../app-wide-controls/error-text';
import ConfigModalActionHandler from './modals/config-modal-action-handler';
import { ResponseError } from '../../data/api-cache';

const ConfigRowWithModal = (props) => {
  const [displayData, setDisplayData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [canEditState, setCanEditState] = useState(false);

  function onDataLoaded(data) {
    setUIDataState(data);
    setIsLoading(false);
    setShowError(false);
  }

  /**
     *
     * @param {*} dataId
     * @param {*} error
     */
  function onDataLoadError(error) {
    setErrorMessage(ResponseError.message(error));
    setShowError(true);
    setIsLoading(false);
  }

  const onDataUpdated = React.useCallback(
    (data) => {
      setUIDataState(data[props.dataID]);
    },
    [],
  );

  function onEditUIRequested() {
    setShowModal(true);
  }

  function onEditingCompleted() {
    setShowModal(false);
  }

  function handleElementError(errorMessage) {
    onDataLoadError(null, errorMessage);
  }

  function setUIDataState(data) {
    const displayValue = data.toDisplay();
    setDisplayData(displayValue);
  }

  useEffect(() => {
    setIsLoading(true);

    let fetchRequest;
    (async function () {
      try {
        fetchRequest = makeCancelable(configurationDataAPI.valuesForKeys(props.dataID));
        const { [props.dataID]: data } = await fetchRequest.promise;
        onDataLoaded(data);
      } catch (error) {
        const message = ResponseError.message(error);
        onDataLoadError(message);
      }
    }());

    configurationDataAPI.receiveUpdates(props.dataID, onDataUpdated);

    if (props.canEdit) {
      props.canEdit().then((_canEdit) => {
        setCanEditState(_canEdit);
      });
    }

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
      configurationDataAPI.cancelUpdates(props.dataID, onDataUpdated);
    };
  }, []);

  function valueElement() {
    if (showError) {
      return <ErrorText text={errorMessage} />;
    } if (props.valueElement) {
      const elemProps = { data: displayData, onError: handleElementError };
      if (props.elementTriggersModal) {
        elemProps.onChange = onEditUIRequested;
      }
      return <props.valueElement {...elemProps} />;
    }
    return displayData;
  }

  function editIconUI() {
    if (canEditState && !props.actionElement) {
      return (
        <IconButton
          variant="compact"
          disabled={isLoading || showError}
          onClick={() => onEditUIRequested()}
        ><EditIcon />
        </IconButton>
      );
    }
    if (props.actionElement) {
      return props.actionElement;
    }
    return null;
  }

  function modalUI() {
    if (props.modalContent) {
      return props.modalContent;
    }
    return null;
  }

  return (
    <Fragment key={`fragment for ConfigReadRow ${props.dataID}`}>
      <RowLayout
        label={props.title}
        value={valueElement()}
        actionElement={editIconUI()}
        isLoading={isLoading}
      />
      <ConfigModalActionHandler
        title={props.title}
        show={showModal}
        onSessionEnded={onEditingCompleted}
        dataID={props.dataID}
        canEdit={canEditState}
        modalContentUI={modalUI()}
      />
    </Fragment>
  );
};

ConfigRowWithModal.propTypes = {
  title: PropTypes.string,
  dataID: PropTypes.string,
  actionElement: PropTypes.element,
  valueElement: PropTypes.func, // () => element
  canEdit: PropTypes.func, // Promise
  elementTriggersModal: PropTypes.bool,
  /* modalContent: PropTypes.element, */
};

const styles = () => ({

  modalErrorContainer: {
    display: 'flex',
  },
  modalErrorIcon: {
    marginRight: 12,
  },
  modalErrorTypographyOverride: {
    paddingTop: 2, // Otherwise doesn't mid-align with icon.
  },
});

export default withStyles(styles)(ConfigRowWithModal);
