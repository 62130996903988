
export function hasValuesForKeys(obj, keys) {
  if (!obj) {
    return false;
  }
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (obj[key] === undefined) {
      return false;
    }
  }
  return true;
}
