import axios from 'axios';

const appAxios = (function () {
  const _axios = axios.create();
  if (process.env.REACT_APP_AXIOS_LOCAL) {
    // Serve up static data files from the public directory.
    _axios.interceptors.request.use((config) => {
      if (config.method === 'get' && config.url === '/api/config') {
        config.url = '/test_config_file.json';
      } else if (config.url === '/metrics') {
        config.url = '/test_metrics_file.txt';
      } else if (config.url === '/api/logs') {
        config.url = '/test_logs_file.txt';
      }
      return config;
    }, (error) => error);

    _axios.interceptors.response.use((response) => response, (error) => {
      if (error.config.url === '/api/config' && error.config.method === 'post') {
        error.response.data = { restartRequired: true };
        return Promise.resolve(error.response);
      }
      return error;
    });
  }
  return _axios;
}());

export default appAxios;
