import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as constants from '../../../helpers/constants';

/**
 * Displays the time with HH:MM and AM/PM controls.
 * Integrations with a ClockView.  Serves same purpose as Material's TimePicker.
 * This was built to work with a bare ClockView as TimePicker could not be separated.
 * https://material-ui-pickers.dev/guides/static-components
 * https://material-ui-pickers.dev/demo/timepicker#static-mode
 * @param {*} props
 */
const ClockControls = (props) => {
  const HOUR_INDEX = 0;
  const MINUTES_INDEX = 2; // 'Colon' dummy tab, occupies 2nd index.
  const AM_INDEX = 0;
  const PM_INDEX = 1;

  const classes = useStyles();
  const [selectedTimeIndex, setSelctedTimeIndex] = React.useState(0);
  const [selectedMerediemIndex, setSelectedMerediemIndex] = React.useState(props.isMorning ? AM_INDEX : PM_INDEX);


  const handleTime = (event, index) => {
    setSelctedTimeIndex(index);
    if (index == HOUR_INDEX && props.onHourSelection) {
      props.onHourSelection();
    } else if (index == MINUTES_INDEX && props.onMinuteSelection) {
      props.onMinuteSelection();
    }
  };

  const handleMeridiem = (event, index) => {
    setSelectedMerediemIndex(index);
    if (!props.onMeridiemSelection) {
      return;
    }
    let value = constants.AM_TIME;
    if (index == PM_INDEX) {
      value = constants.PM_TIME;
    }
    props.onMeridiemSelection(value);
  };

  useEffect(() => {
    setSelectedMerediemIndex(props.isMorning ? AM_INDEX : PM_INDEX);
    return function cleanup() {
    };
  }, [props.isMorning]);

  return (
    <div className={classes.root}>
      <Tabs
        value={selectedTimeIndex}
        onChange={handleTime}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={props.hour} className={classes.tabTimeOverride} {...a11yProps(0)} />
        <Tab label=":" className={classes.tabColonOverride} {...a11yProps(0)} disabled />
        <Tab label={props.minute} className={classes.tabTimeOverride} {...a11yProps(1)} />
      </Tabs>

      <Tabs
        value={selectedMerediemIndex}
        orientation="vertical"
        onChange={handleMeridiem}
        indicatorColor="primary"
        textColor="primary"
        className={classes.verticalTabLayout}
      >
        <Tab label="AM" className={classes.tabMerediemOverride} {...a11yProps(0)} />
        <Tab label="PM" className={classes.tabMerediemOverride} {...a11yProps(1)} />
      </Tabs>
    </div>
  );
};

ClockControls.propTypes = {
  hour: PropTypes.string,
  minute: PropTypes.string,
  isMorning: PropTypes.bool,
  onHourSelection: PropTypes.func,
  onMinuteSelection: PropTypes.func,
  onMeridiemSelection: PropTypes.func,
};

ClockControls.defaultProps = {
  isMorning: true,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  verticalTabLayout: {
    position: 'absolute',
    right: 0,
  },
  tabColonOverride: {
    '@media (min-width: 600px)': {
      minWidth: 4,
      fontSize: 23,
      width: 4,
      paddingLeft: 1,
      paddingRight: 1,
    },
  },
  tabTimeOverride: {
    '@media (min-width: 600px)': {
      minWidth: 52,
      fontSize: 23,
    },
  },
  tabMerediemOverride: {
    '@media (min-width: 600px)': {
      minWidth: 30,
      paddingTop: 3,
      paddingBottom: 0,
      minHeight: 10,

    },
  },
}));

export default ClockControls;
