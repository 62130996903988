// ------- Code setup from React tutorial --------
// https://github.com/facebook/create-react-app
import React from 'react';
import './App.css';

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import unityTheme from '@unity/react-components/theme';
import MetricsPage from './route-pages/metrics-page';
import ConfigurationsPage from './route-pages/configurations-page';
import LogsPage from './route-pages/logs-page';
import SiteLayout from './app-wide-controls/site-layout';
import HomePage from "./route-pages/home-page";

// --------- Ads React requirements: ------------
// https://unitytech.github.io/unity-frontend-lib-docs/docs/@unity/react-components/3.6.0/index.html?page=basics/getting-started

const theme = createMuiTheme(unityTheme);

const App = () => {
  return (
    // Wrap the app in JssProvider to avoid issues like the following:
    // https://github.com/marmelab/react-admin/issues/1782
    <JssProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <SiteLayout>
            <Switch>
              <Route exact path="/">
                <HomePage/>
              </Route>
              <Route path="/metrics-page">
                <MetricsPage/>
              </Route>
              <Route path="/config-page">
                <ConfigurationsPage/>
              </Route>
              <Route path="/logs-page">
                <LogsPage/>
              </Route>
            </Switch>
          </SiteLayout>
        </Router>
      </MuiThemeProvider>
    </JssProvider>
  );
};

export default App;
