import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@unity/react-components/typography';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = ({ mixins, spacing }) => ({
  root: {
    ...mixins.flex('center', 'space-around'),
  },
  edgeToEdge: {
    ...mixins.edgeToEdgeMobileContent(),
  },
  insideCard: {
    flex: 1,
    height: '100%',
  },
  icon: {
    marginBottom: spacing(2),
  },
  cardContent: {
    ...mixins.flex('center', 'center'),
    padding: spacing(10, 6),
    flexDirection: 'column',
    maxWidth: 500,

    '&:last-child': {
      padding: spacing(10, 6),
    },
  },
  cardTitle: {
    marginBottom: spacing(4),

    '&:only-child': {
      marginBottom: 0,
    },
  },
  cardDescription: {
    marginBottom: spacing(2),

    '&:only-child': {
      marginBottom: 0,
    },
  },
});

// eslint-disable-next-line prefer-arrow-callback
export const StateCard = React.forwardRef(function StateCard(props, ref) {
  const {
    ButtonProps,
    classes,
    className,
    description,
    elevation,
    icon,
    insideCard,
    title,
    isLoading,
    ...rest
  } = props;

  const titleElement = (
    <Typography variant="titleSmall" className={classes.cardTitle} component="h3" align="center">
      {title}
    </Typography>
  );

  const descriptionElement = (
    <Typography variant="body" className={classes.cardDescription} align="center">
      {description}
    </Typography>
  );

  const buttonElement = (
    <Button variant="contained" {...ButtonProps}>
      {ButtonProps.label}
    </Button>
  );

  const rootClassNames = classNames(classes.root, className, {
    [classes.edgeToEdge]: !insideCard,
    [classes.insideCard]: insideCard,
  });

  const hasButtonProps = Object.keys(ButtonProps).length > 0;

  const elevationValue = insideCard ? 0 : elevation;

  return (
    <Paper {...rest} ref={ref} className={rootClassNames} elevation={elevationValue}>
      <CardContent className={classes.cardContent}>
        {title && titleElement}
        {description && descriptionElement}
        {isLoading && <CircularProgress size={20} />}
        {hasButtonProps && buttonElement}

      </CardContent>
    </Paper>
  );
});

StateCard.propTypes = {
  classes: PropTypes.object.isRequired,
  ButtonProps: PropTypes.object,
  className: PropTypes.string,
  description: PropTypes.string,
  elevation: PropTypes.number,
  icon: PropTypes.node,
  insideCard: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

StateCard.defaultProps = {
  ButtonProps: {},
  className: null,
  description: null,
  elevation: 2,
  title: null,
  icon: null,
  insideCard: true,
  isLoading: false,
};

export default withStyles(styles, { name: 'StateCard' })(StateCard);
