import React from 'react';
import * as configDataIDs from './config-keys';
import OnChangeButton from '../components/on-change-button';
import ConfigSwitch from '../components/configuration-page/switch';
import DiskEvictionRow from '../components/configuration-page/disk-eviction-row';
import DiskEditor from '../components/configuration-page/editing-controls/disk-eviction-edit';
import TextEditor from '../components/configuration-page/editing-controls/text-editor';
import DurationEditor from '../components/configuration-page/editing-controls/duration-editor';
import ReadOnlyTextEditor from '../components/configuration-page/editing-controls/read-only-text-editor';
import TimeEditor from '../components/configuration-page/editing-controls/time-editor';
import configurationDataAPI from './configuration-data-api';

function metaDataSection() {
  return {
    listHeader: {
      title: 'Metadata',
      description: '',
      id: 'Metadata_id',
    },
    rows: [
      {
        title: 'Current IP Address',
        key: configDataIDs.LastUsedIP,
      },
      {
        title: 'Desired HTTP Port ',
        key: configDataIDs.HTTPPort,
        canEdit: () => new Promise((resolve, reject) => {
          configurationDataAPI.valuesForKeys(configDataIDs.CollabEnabled).then((data) => {
            resolve(!data[configDataIDs.CollabEnabled].data);
          }).catch((error) => {
            resolve(false);
          });
        }),
        modal: (props) => (<TextEditor {...props} />),
      },
      {
        title: 'Current HTTP Port',
        key: configDataIDs.LastUsedHTTPPort,
      },
      {
        title: 'Desired Asset Import Pipeline V2 Port',
        key: configDataIDs.ProtobufPort,
      },
      {
        title: 'Current Asset Import Pipeline V2 Port',
        key: configDataIDs.LastUsedProtobufPort,
      },
      {
        title: 'Install Directory',
        key: configDataIDs.InstallDir,
      },
      {
        title: 'Certificates',
        key: configDataIDs.CertificatePrivateKey,
        elementTriggerModal: true,
        element: (props) => (<OnChangeButton {...props}>REVEAL</OnChangeButton>),
        modal: (props) => (<ReadOnlyTextEditor {...props} />),
      },
    ],
  };
}

function collaborateSection() {
  return {
    listHeader: {
      title: 'Collaborate',
      description: '',
      id: 'Collaborate_id',
    },
    rows: [
      {
        key: configDataIDs.AgentID,
        title: 'ID',
      },
      {
        key: configDataIDs.AgentName,
        title: 'Name',
      },
      {
        title: 'Host Name',
        key: configDataIDs.AgentHostName,
      },
      {
        title: 'Organization ID',
        key: configDataIDs.OrgID,
      },
      {
        title: 'Enable cache',
        key: configDataIDs.CollabEnabled,
        element: (props) => (<ConfigSwitch {...props} dataID={configDataIDs.CollabEnabled} />),
      },
      {
        title: 'Collect Metrics Every',
        key: configDataIDs.MetricsUpdateInterval,
      },
      {
        title: 'Upload Metrics Every',
        key: configDataIDs.MetricReportInterval,
        canEdit: () => Promise.resolve(true),
        modal: (props) => (<DurationEditor {...props} />),
      },
      {
        title: 'Log Lines Uploaded',
        key: configDataIDs.LogUploadEventsBuffer,
        canEdit: () => Promise.resolve(true),
        modal: (props) => (<TextEditor {...props} />),
      },
    ],
  };
}

function logsSection() {
  return {
    listHeader: {
      title: 'Logs',
      description: '',
      id: 'Logs_id',
    },
    rows: [
      {
        title: 'Verbose (Debug Mode)',
        key: configDataIDs.Debug,
        element: (props) => (<ConfigSwitch {...props} dataID={configDataIDs.Debug} />),
      },
    ],
  };
}

function assetImportPipelineV2Section() {
  return {
    listHeader: {
      title: 'Asset Import Pipeline Version 2',
      description: '',
      id: 'AssetImportPipelineVersion2_id',
    },
    rows: [
      {
        title: 'Enable cache',
        key: configDataIDs.ADBV2Enabled,
        element: (props) => (<ConfigSwitch {...props} dataID={configDataIDs.ADBV2Enabled} />),
      },
    ],
  };
}

function maintenanceSection() {
  return {
    listHeader: {
      title: 'Maintenance',
      description: '',
      id: 'Maintenance_id',
    },
    rows: [
      {
        key: configDataIDs.MaintenanceStart,
        title: 'Start Time',
        canEdit: () => Promise.resolve(true),
        modal: (props) => (<TimeEditor {...props} />),
      },
      {
        title: 'Auto Update',
        key: configDataIDs.NoAutoUpdates,
        element: (props) => (<ConfigSwitch {...props} dataID={configDataIDs.NoAutoUpdates} />),
      },
    ],
  };
}

function dataPolicySection() {
  return {
    listHeader: {
      title: 'Data Policy',
      description: '',
      id: 'DataPolicy_id',
    },
    rows: [
      {
        key: configDataIDs.CacheDir,
        title: 'Cache Directory',
        canEdit: () => Promise.resolve(true),
        modal: (props) => (<TextEditor {...props} />),
      },
      {
        key: configDataIDs.CacheMinFreeBytes, // Not required. Ignored.
        title: 'Disk Space Usage',
        element: (props) => (<DiskEvictionRow {...props} />),
        canEdit: () => Promise.resolve(true),
        modal: (props) => (<DiskEditor {...props} />),
      },
    ],
  };
}

export const DataLists = [
  metaDataSection(),
  logsSection(),
  collaborateSection(),
  assetImportPipelineV2Section(),
  maintenanceSection(),
  dataPolicySection(),
];
