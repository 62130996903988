import deburr from 'lodash/deburr';
import memoizeOne from 'memoize-one';


const preformatString = (string) => deburr(string).toLowerCase();

const validateSearchOptions = (options) => {
  if (!Array.isArray(options.searchKeys)) {
    throw new Error('You need to provide an array of keys to search from');
  }

  if (options.searchKeys.length === 0) {
    throw new Error('The array of search keys cannot be empty');
  }
};

const propertyContainsSearchString = (item, searchString) => (key) => (
  item[key] && String(item[key]).toLowerCase().indexOf(searchString) !== -1
);

const itemPropertyContainsSearchString = (items, searchString, options) => (_, index) => (
  options.searchKeys.some(propertyContainsSearchString(items[index], searchString))
);

const preformatItemProperties = (options) => (item) => options.searchKeys
  .reduce((acc, property) => ({ ...acc, [property]: preformatString(item[property]) }), {});

const preformatItemsToSearchFrom = memoizeOne((items, options) => (
  items.map(preformatItemProperties(options))
));

const createSearchFn = (options = {}) => {
  validateSearchOptions(options);

  const searchFn = (itemsToSearchFrom = [], searchString = '') => {
    if (!searchString) {
      return itemsToSearchFrom;
    }

    const caseInsensitiveSearchString = preformatString(searchString);
    const preformattedItemsToSearchFrom = preformatItemsToSearchFrom(itemsToSearchFrom, options);

    return itemsToSearchFrom.filter(
      itemPropertyContainsSearchString(
        preformattedItemsToSearchFrom,
        caseInsensitiveSearchString,
        options,
      ),
    );
  };

  return memoizeOne(searchFn);
};

export default createSearchFn;
