import React, { useState, useEffect } from 'react';
import { convertColumnNamesToProperties as convertColumns, createRow, emptyRow } from '../../helpers/table-component-data';
import metricsDataAPI from '../../data/metrics-data-api';
import { makeCancelable } from '../../helpers/cancellable-promise';
import { hasValuesForKeys } from '../../helpers/object';
import { secondsAsTime, readableBytes } from '../../helpers/readable-text';
import SearchableTable from '../../modified-unity-library-components/table-with-search';
import { ResponseError } from '../../data/api-cache';

// UI Namaes
const ColNameMetric = 'Metric';
const ColNameAssetCache = 'Asset Import Pipeline V2';
const ColNameCollabCache = 'Collab';
const ColNameValue = 'Value';
const columnHeaders = [ColNameMetric, ColNameValue];
const sampledMetricsHeaders = convertColumns(columnHeaders);

const RowNameInternetToCache = 'Internet Data Downloaded into cache';
const RowNameServedFromInternet = 'Data Served from Internet';
const RowNameServedFromCache = 'Data Served from cache';
const RowNameTotalInternetDownloading = 'Time Spent Downloading Internet Data';
const RowNameTimeServingFromCache = 'Time Spent Serving from cache';
const RowNameTimeSaved = 'Time Saved by Accelerator';
const RowNameFilesEvicted = 'Files Evicted from cache';
const RowNameTotalSizeOfFilesEvicted = 'Total Size of Files Evicted';


const rowsNames = [
  RowNameInternetToCache,
  // RowNameServedFromInternet,
  RowNameServedFromCache,
  RowNameTotalInternetDownloading,
  RowNameTimeServingFromCache,
  RowNameTimeSaved,
  RowNameFilesEvicted,
  RowNameTotalSizeOfFilesEvicted,
];

// Metric Names.
const uta_agent_cache_bytes_in = 'uta_agent_cache_bytes_in';
const uta_agent_downstream_bytes_out = 'uta_agent_downstream_bytes_out';
const uta_agent_cache_bytes_out = 'uta_agent_cache_bytes_out';
const uta_agent_download_seconds = 'uta_agent_download_seconds';
const uta_agent_downstream_bytes_out_persec = 'uta_agent_downstream_bytes_out_persec';
const uta_agent_download_saved_seconds = 'uta_agent_download_saved_seconds';
const uta_agent_cache_files_evicted = 'uta_agent_cache_files_evicted';
const uta_agent_cache_bytes_evicted = 'uta_agent_cache_bytes_evicted';


const MetricsTransactionsTable = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState(_createRows()); // Load all rows with empty default state.

  function onMetricsStartDate(date) {
    // days/ years from now
  }

  function onMetricsLoaded(data) {
    setRows(_createRows(data));
    setIsLoading(false);
  }

  function onMetricsFailed(errorMessage) {
    setIsLoading(false);
  }

  function _createRows(metrics) {
    const _rows = [];
    rowsNames.forEach((name) => {
      _rows.push(_rowForName(name, metrics));
    });
    return _rows;
  }

  function _rowForName(rowName, metrics) {
    let row = null;
    switch (rowName) {
      case RowNameInternetToCache: {
        row = _createInternetToCacheRow(metrics);
        break;
      }
      case RowNameServedFromInternet: {
        row = _createServedFromInternetRow(metrics);
        break;
      }
      case RowNameServedFromCache: {
        row = _createServedFromCacheRow(metrics);
        break;
      }
      case RowNameTotalInternetDownloading: {
        row = _createTotalInternetDownloadingRow(metrics);
        break;
      }
      case RowNameTimeServingFromCache: {
        row = _createTimeServingFromCacheRow(metrics);
        break;
      }
      case RowNameTimeSaved: {
        row = _createTimeSavedRow(metrics);
        break;
      }
      case RowNameFilesEvicted: {
        row = _createFilesEvictedRow(metrics);
        break;
      }
      case RowNameTotalSizeOfFilesEvicted: {
        row = _createTotalSizeOfFilesEvictedRow(metrics);
        break;
      }
      default: {
        row = emptyRow();
      }
    }

    row[ColNameMetric] = rowName;
    return row;
  }

  function _createInternetToCacheRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_cache_bytes_in])) {
      return emptyRow(columnHeaders);
    }
    return createRow(columnHeaders, ['', readableBytes(metrics[uta_agent_cache_bytes_in])]);
  }

  function _createServedFromInternetRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_downstream_bytes_out, uta_agent_cache_bytes_out])) {
      return emptyRow(columnHeaders);
    }
    const bytes = (metrics[uta_agent_downstream_bytes_out] - metrics[uta_agent_cache_bytes_out]);
    return createRow(columnHeaders, ['', readableBytes(bytes)]);
  }

  function _createServedFromCacheRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_cache_bytes_out])) {
      return emptyRow(columnHeaders);
    }
    return createRow(columnHeaders, ['', readableBytes(metrics[uta_agent_cache_bytes_out])]);
  }

  function _createTotalInternetDownloadingRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_download_seconds])) {
      return emptyRow(columnHeaders);
    }
    return createRow(columnHeaders, ['', secondsAsTime(metrics[uta_agent_download_seconds])]);
  }

  function _createTimeServingFromCacheRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_cache_bytes_out, uta_agent_downstream_bytes_out_persec])) {
      return emptyRow(columnHeaders);
    }
    const time = (metrics[uta_agent_cache_bytes_out] / metrics[uta_agent_downstream_bytes_out_persec]);
    return createRow(columnHeaders, ['', secondsAsTime(time)]);
  }

  function _createTimeSavedRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_download_saved_seconds])) {
      return emptyRow(columnHeaders);
    }
    return createRow(columnHeaders, ['', secondsAsTime(metrics[uta_agent_download_saved_seconds])]);
  }

  function _createFilesEvictedRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_cache_files_evicted])) {
      return emptyRow(columnHeaders);
    }
    return createRow(columnHeaders, ['', metrics[uta_agent_cache_files_evicted]]);
  }

  function _createTotalSizeOfFilesEvictedRow(metrics) {
    if (!hasValuesForKeys(metrics, [uta_agent_cache_bytes_evicted])) {
      return emptyRow(columnHeaders);
    }
    return createRow(columnHeaders, ['', readableBytes(metrics[uta_agent_cache_bytes_evicted])]);
  }

  // Data loaded.
  useEffect(() => {
    const metrics = [
      uta_agent_cache_bytes_in,
      uta_agent_downstream_bytes_out,
      uta_agent_cache_bytes_out,
      uta_agent_download_seconds,
      uta_agent_cache_bytes_out,
      uta_agent_downstream_bytes_out_persec,
      uta_agent_download_saved_seconds,
      uta_agent_cache_files_evicted,
      uta_agent_cache_bytes_evicted,
    ];
    let fetchRequest;
    (async function () {
      try {
        fetchRequest = makeCancelable(metricsDataAPI.valuesForKeys(metrics));
        const results = await fetchRequest.promise;
        onMetricsLoaded(results);
      } catch (error) {
        const message = ResponseError.message(error);
        onMetricsFailed(message);
      }
    }());

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
    };
  }, []);

  return (
    <div>
      <h3>Accumulated since installation</h3>
      <SearchableTable rows={rows} columns={sampledMetricsHeaders} isLoading={isLoading} />
    </div>
  );
};

export default (MetricsTransactionsTable);
