import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { isEqual } from 'lodash';
import DuoSlider from './duo-slider';

const styles = () => ({
  checkboxes: {
    marginBottom: 26,
  },
});

const checkboxStyles = makeStyles((theme) => ({
  root: {
    color: (props) => {
      if (props.colors.primary) {
        if (props.checked) {
          return theme.palette.primary.dark;
        }
        return theme.palette.primary.light;
      }
      if (props.checked) {
        return theme.palette.success.dark;
      }
      return theme.palette.success.main;
    },
  },
  checked: {},
}));

/**
 *
 * @param {*} props
 */
const ColoredCheckbox = (props) => {
  const classes = checkboxStyles({
    colors: {
      primary: props.themeColor === 'primary',
    },
    checked: props.checked,
  });
  const { themeColor, ...restProps } = props;
  return <Checkbox className={classes.root} {...restProps} />;
};
ColoredCheckbox.propTypes = {
  themeColor: PropTypes.string,
};

ColoredCheckbox.defaultProps = {
  themeColor: 'primary',
};


function hiddenIndices(checkedValues) {
  const _hiddenIndices = [];
  checkedValues.forEach((isChecked, index) => {
    if (!isChecked) {
      _hiddenIndices.push(index);
    }
  });
  return _hiddenIndices;
}

const CheckboxSlider = (props) => {
  const [checkedBoxes, setCheckedBoxes] = React.useState(null);
  const [duoSliderPropsState, setDuoSliderPropsState] = React.useState(props.duoSliderProps || {});
  const [sliderValues, setSliderValues] = React.useState([]); // Used only to forward to onChange.

  React.useEffect(() => {
    if (isEqual(props.initialCheckedValues, checkedBoxes)) {
      return;
    }
    setDuoSliderPropsState((prev) => ({ ...prev, hideIndices: hiddenIndices(props.initialCheckedValues) }));
    setCheckedBoxes(props.initialCheckedValues);
    return function cleanup() { };
  }, [props.initialCheckedValues]);

  React.useEffect(() => {
    setDuoSliderPropsState((prev) => ({ ...prev, ...props.duoSliderProps }));
    setSliderValues(props.duoSliderProps.initialValues);
    return function cleanup() { };
  }, [props.duoSliderProps]);

  const onCheckboxChange = (index) => (event) => {
    const selections = [
      index === 0 ? event.target.checked : checkedBoxes[0],
      index === 1 ? event.target.checked : checkedBoxes[1],
    ];
    setDuoSliderPropsState((prev) => ({ ...prev, hideIndices: hiddenIndices(selections) }));
    setCheckedBoxes(selections);
    props.onChange({
      checked: selections,
      slider: sliderValues,
    });
  };

  /**
   * Note: Slider is exepcted to not be active
   * when no checkboxes selected.
   * @param {*} values
   */
  const handleSliderChange = (values) => {
    if (isEqual(values, sliderValues)) {
      return;
    }
    setSliderValues(values);
    props.onChange({
      checked: checkedBoxes,
      slider: values,
    });
  };


  // Defaults while starting up.  Cannot set a default state for
  // the checkboxes as that affects equality checks and causes misrenders.
  let isFirstChecked = false;
  let isSecondChecked = false;
  if (checkedBoxes) {
    [isFirstChecked, isSecondChecked] = checkedBoxes;
  }

  const { classes } = props;

  return (
    <>
      <FormGroup row className={classes.checkboxes}>
        <FormControlLabel
          label={props.labels[0]}
          disabled={props.disabled}
          control={(
            <ColoredCheckbox
              checked={isFirstChecked}
              onChange={onCheckboxChange(0)}
              value="checkedA"
              color="default" // Required for custom color
              themeColor="primary"
            />
          )}
        />
        <FormControlLabel
          label={props.labels[1]}
          disabled={props.disabled}
          control={(
            <ColoredCheckbox
              checked={isSecondChecked}
              onChange={onCheckboxChange(1)}
              value="checkedB"
              color="default" // Required for custom color
              themeColor="secondary"
            />
      )}
        />
      </FormGroup>
      <DuoSlider
        disabled={props.disabled}
        {...duoSliderPropsState}
        onChange={handleSliderChange}
        sliderProps={props.sliderProps}
      />
    </>
  );
};

CheckboxSlider.propTypes = {
  duoSliderProps: PropTypes.object,
  sliderProps: PropTypes.object,
  disabled: PropTypes.bool,
  initialCheckedValues: PropTypes.arrayOf(PropTypes.bool),
  labels: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

CheckboxSlider.defaultProps = {
  disabled: false,
  labels: ['', ''],
  onChange: () => {},
};

export default withStyles(styles, { withTheme: true })(CheckboxSlider);
