import React from 'react';
import { Link, matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import TuneIcon from '@material-ui/icons/Tune';
import DescriptionIcon from '@material-ui/icons/Description';
import BarChartIcon from '@material-ui/icons/BarChart';
import Sidebar from '@unity/react-components/sidebar/sidebar';
import SidebarLink from '@unity/react-components/sidebar/sidebar-link';
import SidebarList from '@unity/react-components/sidebar/sidebar-list';
import flow from 'lodash/flow';


/**
 * Menu that changes the route (hash routing) when clicked.
 * 
 * A subset of functionality given by the example in Unity's frontend-lib.
 * https://unitytech.github.io/unity-frontend-lib-docs/docs/@unity/react-components/3.10.0/index.html?page=components/sidebar
 * Slack: #unity-frontend-lib @joona @maciej
 */
class SideMenu extends React.Component {
    state = {
        isProjectsFetching: false,
        isProjectsFetchError: false,
        isIconFetching: false,
    };

    onSelection = () => {
        if (this.props.onSelectionCallback) {
            this.props.onSelectionCallback();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Sidebar classes={{ root: classes.sideBarRoot }} >
                <SidebarList>
                    {/* <CustomLink icon={PeopleIcon} text="Users" to="/users-page" onClick={this.onSelection}/> */}
                    <CustomLink icon={BarChartIcon} text="Metrics" to="/metrics-page" onClick={this.onSelection}/>
                    <CustomLink icon={TuneIcon} text="Configuration" to="/config-page" onClick={this.onSelection}/>
                    <CustomLink icon={DescriptionIcon} text="Logs" to="/logs-page" onClick={this.onSelection}/>
                    
                </SidebarList>
                </Sidebar>
            </React.Fragment>
        );
    }
}

SideMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    onSelectionCallback: PropTypes.func
};

const styles = ({ breakpoints }) => ({
    sideBarRoot: {
        paddingTop: 0,
        [breakpoints.up('md')]: {
        top: 'auto',
        left: 'auto',
        position: 'relative',
        },
    },
});

export default flow(withRouter, withStyles(styles))(SideMenu);


const CustomLink = ({ to, exact, strict, ...rest }) => {
    let hashUrl = "#" + to;
    const isActive = matchPath(window.location.hash, { path: hashUrl, exact, strict }) !== null;

    return (
        <SidebarLink
            isActive={isActive}
            component={props => <Link to={to} {...props} />}
            {...rest}
        />
    );
};

CustomLink.propTypes = {
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    to: PropTypes.string,
};

CustomLink.defaultProps = {
    exact: false,
    strict: false,
    to: null,
};