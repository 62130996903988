import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SecondaryNavigationToggler from '@unity/react-labs/app-header/secondary-navigation-toggler';
import Drawer from '@material-ui/core/Drawer';

/**
 * Comination of Material UI Control and Unity's frontend-code:
 * git@github.com:UnityTech/unity-frontend-lib.git
 * https://material-ui.com/components/drawers/#temporary-drawer
 */
class SideMenuDrawer extends React.Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        handleOpen: PropTypes.func.isRequired,
      };
  
    render() {
        const { isOpen, handleClose, handleOpen, children, list } = this.props;
        return (
            <div>
                <SecondaryNavigationToggler
                    aria-haspopup="true"
                    aria-label="Open menu"
                    onClick={handleOpen} />
                <Drawer open={isOpen} onClose={handleClose}>
                    <div
                        className={list}
                        role="presentation"
                        onKeyDown={handleClose}>
                        {children}
                    </div>
                </Drawer>
            </div>
        );
    }
}

const styles = () => ({
    list: {
      width: 250,
    },
  });

export default withStyles(styles)(SideMenuDrawer);