import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { isObject } from 'lodash';
import { makeCancelable } from '../../../helpers/cancellable-promise';
import { BUTTONS_SAVE, BUTTONS_CLOSE } from '../../../helpers/constants';
import configurationDataAPI from '../../../data/configuration-data-api';
import ModalLayout from './modal-layout';
import { ResponseError } from '../../../data/api-cache';


const ConfigModalActionHandler = (props) => {
  const [showSaveError, setShowSaveError] = useState(false);
  const [errorOnSaveMessage, setErrorOnSaveMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [saveTrigger, setSaveTrigger] = useState(0);

  /**
     * User has 'saved' or 'cancelled' the edit form.
     * @param {*} modalUserContent
     * @param {*} shouldSave
     */
  function onEditEnded(shouldSave) {
    removeErrorUI(); // This save may be a retry after an error. Error UI may still be up.

    if (!shouldSave) {
      removeEditUI();
      return;
    }
    if (userData === null) {
      removeEditUI();
      return;
    }

    setSaveTrigger((prev) => prev + 1);
  }

  function onSaveComplete(data) {
    removeEditUI();
  }

  function onSaveError(unSavedData, message) {
    let serverMessageUI = null;
    if (message && message.length > 0) {
      serverMessageUI = (
        <span className={props.classes.caption}>
          Server message: {message}
        </span>
      );
    }

    const displayValue = isObject(unSavedData) ? unSavedData.toDisplay() : unSavedData.toString();
    const fullMessage = (
      <span>Failed saving <em>{displayValue}</em>. Give it another shot.<br />
        {serverMessageUI}
      </span>
    );

    setErrorOnSaveMessage(fullMessage);
    setShowSaveError(true);
  }

  function removeErrorUI() {
    setErrorOnSaveMessage(null);
    setShowSaveError(false);
  }

  function removeEditUI() {
    removeErrorUI();
    props.onSessionEnded();
  }

  function onEditListener(modalData) {
    setUserData(modalData);
  }

  function isContentLoading(isLoading) {

  }

  useEffect(() => {
    if (saveTrigger === 0) {
      return;
    }
    let saveRequest;
    (async function () {
      try {
        saveRequest = makeCancelable(configurationDataAPI.save(userData));
        const result = await saveRequest.promise;
        onSaveComplete(result);
      } catch (error) {
        const message = ResponseError.message(error.message || error);
        const data = (error.data && error.data[props.dataID])
          || configurationDataAPI.embedTranslators(userData)[props.dataID];
        onSaveError(data, message);
      }
    }());

    return function cleanup() {
      if (saveRequest) {
        saveRequest.cancel();
      }
    };
  }, [saveTrigger]);

  function contentUI() {
    if (props.modalContentUI) {
      return <props.modalContentUI dataID={props.dataID} onEditListener={onEditListener} isLoadingListener={isContentLoading} />;
    }
    return null;
  }

  function modalUI() {
    if (!props.show) {
      return null;
    }
    let buttonLayout = BUTTONS_CLOSE;
    if (props.canEdit === true) {
      buttonLayout = BUTTONS_SAVE;
    }

    return (
      <ModalLayout
        title={props.title}
        isOpen={props.show}
        onExit={onEditEnded}
        errorMessage={errorOnSaveMessage}
        showError={showSaveError}
        buttonLayout={buttonLayout}
        content={contentUI()}
      />
    );
  }

  return (
    <div>
      {modalUI()}
    </div>
  );
};

const styles = ({ unityTypography }) => ({
  caption: unityTypography.caption,
});

ConfigModalActionHandler.propTypes = {
  dataID: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool,
  onSessionEnded: PropTypes.func,
  canEdit: PropTypes.bool,
  modalContentUI: PropTypes.func, // Resolves to:  PropTypes.element
};

ConfigModalActionHandler.defaultProps = {
  title: '',
  show: false,
  modalContentUI: null,
};

export default withStyles(styles)(ConfigModalActionHandler);
