import * as constants from '../helpers/constants';
import * as configKeys from './config-keys';
import { applyCountSuffix } from '../helpers/readable-text';

/**
 * Converts server object of 'string' to view model of 'string'.
 * (No logic required.)
 */
class TextTranslator {
  static toViewModel(serverModel) {
    return serverModel;
  }

  static toServerModel(viewModel) {
    return viewModel;
  }

  static toDisplay(viewModel) {
    return viewModel;
  }
}

/**
 * Converts server object of 'boolean' to view model of 'boolean'.
 * (No logic required.)
 */
class BooleanTranslator {
  static toViewModel(serverModel) {
    return serverModel;
  }

  static toServerModel(viewModel) {
    return viewModel;
  }

  /**
   * This expects the UI to be displaying a boolean value within a control
   * (e.g. a Switch/Toggle) rather than displaying as text (e.g. "true", "false").
   * @param {Boolean} viewModel
   * @returns Boolean
   */
  static toDisplay(viewModel) {
    return viewModel;
  }
}

/**
 * Converts server object of 'boolean' to its _INVERSE_ view model of 'boolean',
 * negating the value to represent the _OPPOSITE_ for the UI.
 */
class InverseBooleanTranslator {
    static toViewModel(serverModel) {
        return !serverModel;
    }

    static toServerModel(viewModel) {
        return !viewModel;
    }

    /**
     * This expects the UI to be displaying a boolean value within a control
     * (e.g. a Switch/Toggle) rather than displaying as text (e.g. "true", "false").
     * @param {Boolean} viewModel
     * @returns Boolean
     */
    static toDisplay(viewModel) {
        return !viewModel;
    }
}

/**
 * Converts server number object, within the set of Integers.
 */
class IntegerTranslator {
  static toViewModel(serverModel) {
    return serverModel;
  }

  static toServerModel(viewModel) {
    let integer = viewModel;
    if (typeof viewModel === 'string') {
      integer = parseInt(viewModel);
      if (isNaN(integer)) {
        throw new Error('A number is required');
      }
    }

    return integer;
  }

  static toDisplay(viewModel) {
    if (typeof port === 'string') {
      return viewModel;
    }
    return viewModel.toString();
  }
}

/**
 * Convert Port numbers to having reasonable default messages
 */
const DEFAULT_PORT_STR = 'Unset value (0)';
class PortTranslator {
  static toViewModel(serverModel) {
    return IntegerTranslator.toViewModel(serverModel);
  }

  static toServerModel(port) {
    if (typeof port === 'string' && port === DEFAULT_PORT_STR) {
      port = 0;
    }
    return IntegerTranslator.toServerModel(port);
  }

  static _convertToDescription(port) {
    if (typeof port === 'string' && port === DEFAULT_PORT_STR) {
      return port;
    }
    try {
      port = this.toServerModel(port);
    } catch (e) {
      return port.toString();
    }
    port = (port === 0) ? DEFAULT_PORT_STR : IntegerTranslator.toDisplay(port);
    return port;
  }

  static _convertFromDescription(port) {
    if (typeof port === 'string' && port !== DEFAULT_PORT_STR) {
      return port;
    }
    return IntegerTranslator.toDisplay(port);
  }

  /**
   *
   * @param {*} port
   * @param {*} isWriteInput Is 'port' used in a form input or
   *                        some input area (vs read-only/description)
   */
  static toDisplay(port, isWriteInput) {
    if (isWriteInput) {
      return this._convertFromDescription(port);
    }
    return this._convertToDescription(port);
  }
}


/**
 * Converts a number representating bytes to a string.  Text is adding
 * when converting again to UI display.
 */
class BytesTranslator {
  static toViewModel(serverModel) {
    return serverModel;
  }

  static toServerModel(viewModel) {
    let serverModel = viewModel;
    if (typeof viewModel === 'string') {
      serverModel = parseInt(viewModel);
      if (isNaN(serverModel)) {
        throw new Error('[BytesTranslator] A number is required');
      }
    }
    return serverModel;
  }

  static toDisplay(viewModel) {
    return `${viewModel} bytes`;
  }
}

/**
 * Translates 24 hour string from the server in an object with fields
 * for each unit.
 * e.g. "01:00" -> { "hourAs24hr": Number, "minute": Number }
 */
class ClockTranslator {
  /**
   * Creates an object from a string representation of time.
   * @param {String} time24hrText e.g.  "01:00"
   * @returns {Object} time
   * {number} time.hourAs24hr - Integer representing the hour of a clock time. 0 <= hour <= 24
   * {number} time.minute - Integer representing minutes < 60.  Key = "constants.MINUTE"
   */
  static parseTime(time24hrText) {
    const TEXT_LENGTH = 5;
    if (!time24hrText || time24hrText.length != TEXT_LENGTH) {
      console.error(`[${ClockTranslator.name}:parseTime Given unknown 24hr text: ${time24hrText}`);
    }

    const COLON_INDEX = 2;
    const hourText = time24hrText.substring(0, COLON_INDEX);
    const minuteText = time24hrText.substring(COLON_INDEX + 1);
    const time = {
      hourAs24hr: parseInt(hourText),
      [constants.MINUTE]: parseInt(minuteText),
    };
    return time;
  }

  /**
   *
   * @param {number} number
   * @returns {string} Text of a number front padded with zero if single digit.
   */
  static _toDoubleDigitString(number) {
    let text = number.toString();
    if (number < 10) {
      text = `0${text}`;
    }
    return text;
  }

  static toViewModel(serverModel) {
    return this.parseTime(serverModel);
  }

  static toServerModel(viewModel) {
    if (typeof viewModel === 'string') {
      return viewModel;
    }
    const hourText = this._toDoubleDigitString(viewModel.hourAs24hr);
    const minutesText = this._toDoubleDigitString(viewModel[constants.MINUTE]);
    return `${hourText}:${minutesText}`;
  }

  /**
   * UI text to display time with meridian suffix.
   * e.g. "12 pm" or "9:33 am"
   * @param {object} viewModel
   * {number} viewModel.hourAs24hr - 0 <= hours <= 24 (not enforced)
   * {number} viewModel.minute - 0 <= minutes <= 59 (not enforced)
   * @returns {string}
   */
  static toDisplay(viewModel) {
    if (typeof viewModel === 'string') {
      viewModel = this.toViewModel(viewModel);
    }

    let hourAs12hr = viewModel.hourAs24hr % 12;
    if (hourAs12hr === 0) {
      hourAs12hr = 12;
    }

    let minute = '';
    if (viewModel[constants.MINUTE] > 0) {
      minute = minute.concat(':', viewModel[constants.MINUTE].toString());
    }
    const suffix = viewModel.hourAs24hr < 12 ? constants.AM_TIME : constants.PM_TIME;
    return `${hourAs12hr.toString()}${minute} ${suffix}`;
  }
}

/**
 * Translates between string and object for text of the form:
 * "[hour]h[minutes]m[seconds]s", e.g. "24h0m0s"
 */
class DurationTranslator {
  /**
   * Parses out the number value a duration time unity (hours, minutes, seconds, etc.)
   * @param {String} text - Of the form "hms" e.g. "12h13m2s"
   * @param {*} unitShortSymbol - unit symbol found in 'text'. e.g. One of "h", "m", "s"
   * @returns {string} The string value of the number in front of the given unit.
   * May be undefined for empty 'text' or unfound unit
   */
  static getValueForUnit(text, unitShortSymbol) {
    if (!text || text.length === 0) {
      return undefined;
    }

    let parsed;
    const charIndex = text.indexOf(unitShortSymbol);
    if (charIndex > 0) {
      let firstIndex = -1;
      for (let i = charIndex - 1; i >= 0; i--) {
        const parse = parseInt(text.charAt(i));
        if (isNaN(parse)) {
          break;
        }
        firstIndex = i;
      }
      if (firstIndex > -1) {
        parsed = text.substring(firstIndex, charIndex);
      }
    }
    return parsed;
  }

  static toViewModel(serverModel) {
    return {
      hours: this.getValueForUnit(serverModel, 'h'),
      minutes: this.getValueForUnit(serverModel, 'm'),
      seconds: this.getValueForUnit(serverModel, 's'),
    };
  }

  static toServerModel(viewModel) {
    if (typeof viewModel === 'string') {
      return viewModel;
    }

    let serverModel = '';
    if (viewModel.hours) {
      serverModel = serverModel.concat(viewModel.hours, 'h');
    }
    if (viewModel.minutes) {
      serverModel = serverModel.concat(viewModel.minutes, 'm');
    }
    if (viewModel.seconds) {
      serverModel = serverModel.concat(viewModel.seconds, 's');
    }

    return serverModel;
  }

  /**
   * Creates end-user readable string from a durations object.
   * Output uses correct grammar for plural/singular values.
   * e.g. "12 hours 3 minutes 1 second"
   * Note: Units are not displayed for missing keys.
   *
   * @param {Object} viewModel
   * {string} [viewModel.hours] - Integer
   * {string} [viewModel.minutes] - Integer
   * {string} [viewModel.seconds] - Integer
   *
   * @returns {String} Empty string when parameter has no keys.
   */
  static toDisplay(viewModel) {
    if (!viewModel) {
      return '';
    }

    if (typeof viewModel === 'string') {
      viewModel = this.toViewModel(viewModel);
    }

    let str = '';
    if (viewModel.hours && viewModel.hours !== '0') {
      str = str.concat(applyCountSuffix(viewModel.hours, 'hour'));
    }
    if (viewModel.minutes && viewModel.minutes !== '0') {
      str = str.concat(' ', applyCountSuffix(viewModel.minutes, 'minute'));
    }
    if (viewModel.seconds && viewModel.seconds !== '0') {
      str = str.concat(' ', applyCountSuffix(viewModel.seconds, 'second'));
    }
    return str;
  }
}


export const translatorForKey = (configKey) => {
  let translator = null;
  switch (configKey) {
    // String values
    case configKeys.AgentID:
    case configKeys.AgentName:
    case configKeys.AgentHostName:
    case configKeys.EnvironmentType:
    case configKeys.OrgID:
    case configKeys.IP:
    case configKeys.LastUsedIP:

    // Long strings
    case configKeys.AgentAuth:
    case configKeys.JWTPublicKey:
    case configKeys.JWTPublicKeyFile:
    case configKeys.CertificatePrivateKey:
    case configKeys.CertPEM:
    case configKeys.KeyPEM:
    case configKeys.InstallDir:
    case configKeys.CacheDir:

    // URLs
    case configKeys.CertificateURL:
    case configKeys.UTAServiceURL:
    case configKeys.CollabURL:
      translator = new TextTranslator();
      break;

    case configKeys.HTTPPort:
    case configKeys.ProtobufPort:
      translator = new PortTranslator();
      break;

    // Numbers
    case configKeys.LastUsedHTTPPort:
    case configKeys.LastUsedProtobufPort:
    case configKeys.CacheDirSplit:
    case configKeys.CacheMinFreePercent:
    case configKeys.CacheMaxUsedPercent:
    case configKeys.CacheEvictUntilFreePercent:
    case configKeys.CacheEvictUntilUsedPercent:
    case configKeys.PrefetchMaxPending:
    case configKeys.PrefetchConcurrency:
    case configKeys.DefaultHTTPAttempts:
    case configKeys.UTAServiceAttempts:
    case configKeys.CollabAttempts:
    case configKeys.ProtobufPerStreamBuffer:
    case configKeys.LogUploadEventsBuffer:
      translator = new IntegerTranslator();
      break;

    // Bytes (e.g. 65536)
    case configKeys.UTAServiceChunkSize:
    case configKeys.DefaultHTTPChunkSize:
    case configKeys.CacheEvictUntilFreeBytes:
    case configKeys.CacheEvictUntilUsedBytes:
    case configKeys.CollabChunkSize:
    case configKeys.CacheMinFreeBytes:
    case configKeys.CacheMaxUsedBytes:
      translator = new BytesTranslator();
      break;

    // Durations (e.g. "10m0s")
    case configKeys.MetricReportInterval:
    case configKeys.ConfigQueryInterval:
    case configKeys.MetricsUpdateInterval:
    case configKeys.MaintenanceDuration:
    case configKeys.CacheMonitorInterval:
    case configKeys.DefaultHTTPTimeout:
    case configKeys.DefaultHTTPKeepAlive:
    case configKeys.DefaultHTTPDelay:
    case configKeys.UTAServiceTimeout:
    case configKeys.UTAServiceKeepAlive:
    case configKeys.UTAServiceDelay:
    case configKeys.CollabTimeout:
    case configKeys.CollabKeepAlive:
    case configKeys.CollabDelay: // seconds.
      translator = new DurationTranslator();
      break;

    // Clock time (e.g. "01:00")
    case configKeys.MaintenanceStart:
      translator = new ClockTranslator();
      break;

    // Boolean
    case configKeys.Debug:
    case configKeys.CollabEnabled:
    case configKeys.ADBV2Enabled:
    case configKeys.LegacyEnabled:
    case configKeys.DefaultHTTPInsecureSkipVerify:
    case configKeys.DefaultHTTPAutoEnableHTTP2:
    case configKeys.UTAServiceInsecureSkipVerify:
    case configKeys.UTAServiceAutoEnableHTTP2:
    case configKeys.CollabInsecureSkipVerify:
    case configKeys.CollabAutoEnableHTTP2:
    case configKeys.ProtobufTLSRequired:
    case configKeys.ProtobufAuthRequired:
      translator = new BooleanTranslator();
      break;

    // InverseBoolean
    case configKeys.NoAutoUpdates:
      translator = new InverseBooleanTranslator();
      break;

    default:
      console.error('[ConfigTranslatorFactory:configTranslatorForKey] unhandled key: ', configKey);
  }
  return translator;
};
