import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import { withStyles } from '@material-ui/core';
import Typography from '@unity/react-components/typography';
import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';
import * as configDataIDs from '../../data/config-keys';
import { makeCancelable } from '../../helpers/cancellable-promise';
import configurationDataAPI from '../../data/configuration-data-api';
import { readableBytes } from '../../helpers/readable-text';
import ErrorText from '../../app-wide-controls/error-text';
import { ResponseError } from '../../data/api-cache';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});


const createMessage = (percentUsed, percentFree, bytesUsed, bytesFree) => {
  const lines = [];
  let allocated = null;

  percentUsed = isNumber(percentUsed) ? percentUsed : -1;
  percentFree = isNumber(percentFree) ? percentFree : -1;
  bytesUsed = isNumber(bytesUsed) ? bytesUsed : -1;
  bytesFree = isNumber(bytesFree) ? bytesFree : -1;

  if (percentUsed >= 0 || bytesUsed >= 0) {
    allocated = 'Can use ';
    if (percentUsed >= 0 && bytesUsed >= 0) {
      allocated += `the greater of ${percentUsed}% or ${readableBytes(bytesUsed)}`;
    } else if (percentUsed >= 0) {
      allocated += `${percentUsed}%`;
    } else {
      allocated += `${readableBytes(bytesUsed)}`;
    }
  }
  if (allocated) {
    lines.push(allocated);
  }

  let free = null;
  if (percentFree >= 0 || bytesFree >= 0) {
    free = 'Cannot use ';
    if (percentFree >= 0 && bytesFree >= 0) {
      free += `the lesser of ${percentFree}% or ${readableBytes(bytesFree)}`;
    } else if (percentFree >= 0) {
      free += `${percentFree}%`;
    } else {
      free += `${readableBytes(bytesFree)}`;
    }
  }
  if (free) {
    lines.push(free);
  }

  if (lines.length === 0) {
    lines.push('No disk allocation settings made.  Edit to create some.');
  }
  return lines;
};

const DiskEvictionRow = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [messageLines, setMessageLines] = useState([]);
  const [dataState, setDataState] = useState({});

  useEffect(() => {
    let fetchRequest;
    const keys = [
      configDataIDs.CacheMinFreePercent,
      configDataIDs.CacheMinFreeBytes,
      configDataIDs.CacheMaxUsedBytes,
      configDataIDs.CacheMaxUsedPercent];


    const onDataUpdatedRow = (data) => {
      const configValues = { ...dataState, ...data };
      const lines = createMessage(
        configValues[configDataIDs.CacheMaxUsedPercent] && configValues[configDataIDs.CacheMaxUsedPercent].data,
        configValues[configDataIDs.CacheMinFreePercent] && configValues[configDataIDs.CacheMinFreePercent].data,
        configValues[configDataIDs.CacheMaxUsedBytes] && configValues[configDataIDs.CacheMaxUsedBytes].data,
        configValues[configDataIDs.CacheMinFreeBytes] && configValues[configDataIDs.CacheMinFreeBytes].data,
      );
      setMessageLines(lines);
      setDataState(configValues);
    };


    (async function () {
      try {
        fetchRequest = makeCancelable(configurationDataAPI.valuesForKeys(keys));
        const configValues = await fetchRequest.promise;
        onDataUpdatedRow(configValues);
        configurationDataAPI.receiveUpdates(keys, onDataUpdatedRow);
        setIsLoading(false);
        setShowError(false);
      } catch (error) {
        setIsLoading(false);
        const message = ResponseError.message(error);
        if (props.onError) {
          props.onError(message);
        } else {
          setErrorMessage(message);
          setShowError(true);
        }
      }
    }());

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
      configurationDataAPI.cancelUpdates(keys, onDataUpdatedRow);
    };
  }, []);

  const { classes } = props;

  return (
    <div className={classes.root}>
      { isLoading && <SkeletonLoader width={400} height={20} rounded /> }
      { showError && <ErrorText text={errorMessage} /> }
      { !isLoading && !showError
         && messageLines.map((message, index) => (
           <Typography variant="bodyLight" wordBreak="word" component="div" key={index}>
             {message}
           </Typography>
         ))}
    </div>
  );
};

DiskEvictionRow.propTypes = {
};

export default withStyles(styles, { withTheme: true })(DiskEvictionRow);
export { createMessage };
