import numeral from 'numeral';
import humanizeDuration from 'humanize-duration';


export const secondsAsTime = (secondsNumber) => {
  const milliSeconds = _convertSecsToMilliSeconds(secondsNumber);
  const toTime = humanizeDuration(milliSeconds, { round: true });
  return toTime;
};

export const readableBytes = (bytesNumber) => {
  const bytes = numeral(bytesNumber);
  const formatted = bytes.format('0.0 b');
  return formatted;
};

function _convertSecsToMilliSeconds(secs) {
  return secs * 1000;
}

export const applyCountSuffix = (countText, unitSingular) => {
  const count = parseInt(countText);
  return `${countText} ${unitSingular}${count !== 1 ? 's' : ''}`;
};
