import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import withOpenability, { openableShape } from '@unity/react-components/hoc/with-openability';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import StateCard from '../../../modified-unity-library-components/state-card';


const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4),
  },
}));

const AgentRestartModal = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const { dialogOpenable, errorOpenable, description, canClose } = props;

  function close() {
    setIsModalOpen(false);
    dialogOpenable.handleClose();
    if (props.onClose) {
      props.onClose();
    }
  }

  const classes = useStyles();
  return (
    <>
      <Dialog open={isModalOpen} onClose={dialogOpenable.handleClose} disableBackdropClick disableEscapeKeyDown>
        <StateCard
          title="Restarting the Accelerator Agent"
          description={description}
          isLoading={!canClose}
          ButtonProps={{
            color: 'primary',
            label: 'Close',
            disabled: !props.canClose,
            className: classes.button,
            onClick: () => { close(); },
          }}
        />
      </Dialog>
    </>
  );
};

AgentRestartModal.propTypes = {
  dialogOpenable: openableShape.isRequired,
  errorOpenable: openableShape.isRequired,
  onClose: PropTypes.func,
  canClose: PropTypes.bool,
  description: PropTypes.string,
};

AgentRestartModal.defaultProps = {
  canClose: false,
  description: '',
};

export default withOpenability('dialogOpenable', 'errorOpenable')(AgentRestartModal);
