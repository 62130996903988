import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Typography from '@unity/react-components/typography';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';
import ValueLabelComponent from './slider-label';

const useStyles = makeStyles({
  root: {
    margin: 0,
    width: '100%',
    opacity: (props) => (props.disabled ? 0.4 : 1),
  },

  sliderRoot: {
    '& span .MuiSlider-track': {
      backgroundColor: 'black',
    },
  },
  thumbIndicator: {
    '&': {
      // Display the 2nd index is first is invisible.
      backgroundColor: (props) => {
        if (props.values.length === 2 && props.values[0] === null) {
          return props.colors.second;
        }
        return props.colors.first;
      },
    },

    '& ~ &': {
      backgroundColor: (props) => props.colors.second,
    },
  },
  track: {
    backgroundColor: 'black',
    '& $rail': {
      opacity: 1,
      backgroundColor: '#ddd',
    },
  },
  trackInverted: {
    '& $track': {
      backgroundColor: '#ddd',

    },
    // Displayed when no thumbs/control are present.
    '& $rail': {
      opacity: 1,
      backgroundColor: '#ddd',
    },
  },
  rail: {
    background: (props) => {
      // Single colour from left to thumb.  Unselected color after till end (implicit 100%).
      const firstColourOnly = `linear-gradient(to right, 
        ${props.colors.first} 0, 
        ${props.colors.first} ${props.values[0]}%, 
        ${props.colors.unselected} ${props.values[0]}%)`;

      let gradient = firstColourOnly;

      if (props.values.length === 2) {
        if (props.values[0] === null && props.values[1] === null) {
          gradient = `linear-gradient(to right, ${props.colors.unselected} 0,  ${props.colors.unselected} 100%`;
        } else if (props.values[0] === null) {
          // Only display right-side thumb to end.
          gradient = `linear-gradient(to right, 
            ${props.colors.unselected} 0, 
            ${props.colors.unselected} ${props.values[1]}%, 
            ${props.colors.second} ${props.values[1]}%)`;
        } else if (props.values[1] !== null) {
          gradient = `linear-gradient(to right, 
            ${props.colors.first} 0, 
            ${props.colors.first} ${props.values[0]}%, 
            ${props.colors.unselected} ${props.values[0]}%,
            ${props.colors.unselected} ${props.values[1]}%, 
            ${props.colors.second} ${props.values[1]}%)`;
        }
      }

      return gradient;
    },
    opacity: 1,
  },
});


const stripNullValues = (values) => {
  const filtered = values.filter((item) => item != null);
  return filtered;
};

const toggleInverted = (values) => {
  const copy = [...values];
  if (copy[1] !== null) {
    copy[1] = 100 - copy[1];
  }
  return copy;
};

/**
 * Supports max of 2 slider values.
 * Always supply 'values' of 2 elements, with 'null' for any
 * control to be hidden.
 * Second value is distance from right-most edge out of 100.
 * Inverts values so any second value is displayed from the rightside-in.
 *
 * @param {*} props
 */
const DuoSlider = (props) => {
  if (props.initialValues.length !== 2) {
    console.error(`Must provide 2 values to DuoSlider.  Reveived ${props.initialValues.length}`);
    return <div />;
  }

  const [sliderPropsState, setSliderPropsState] = React.useState({
    value: [0],
    track: 'inverted',
    disabled: props.disabled,
  });

  // 'trackedValues' stores all Slider values whether visible or not.
  // They are not inverted (2nd value needs to be inverted to return to caller).
  const [trackedValues, setTrackedValues] = React.useState(props.initialValues);
  const [prevHiddenIndices, setPrevHiddenIndices] = React.useState([]);
  const [prevInitialValues, setPrevInitialValues] = React.useState([]);


  const classes = useStyles({
    values: valuesWithHiddenAsNull(trackedValues),
    colors: {
      first: props.colors.primary[0],
      second: props.colors.primary[1],
      unselected: '#ddd',
    },
    disabled: props.disabled,
  });

  function valuesWithHiddenAsNull(allValues) {
    const values = [...allValues];
    for (const index of props.hideIndices) {
      values[index] = null;
    }
    return values;
  }

  /**
   *
   * @param {Array(Number)} sliderValue
   */
  function updateTrackedValues(sliderValue) {
    let changesAsArray = sliderValue;
    if (sliderValue.length === 1) {
      changesAsArray = [...trackedValues];
      const visibleIndex = (props.hideIndices[0] + 1) % 2;
      changesAsArray[visibleIndex] = sliderValue[0];
    }
    setTrackedValues(changesAsArray);
    return changesAsArray;
  }

  function updateSliderState(newProps) {
    setSliderPropsState((prev) => ({ ...prev, ...newProps }));
  }

  /**
   * Watch both properties as they may be updated together
   * and changes depend on state updates.
   * When they are separated, logic reads from stale state as all
   * useEffect(s) run before state is updated internally.
   */
  React.useEffect(() => {
    let _trackedValues = trackedValues;
    if (!isEqual(prevInitialValues, props.initialValues)) {
      _trackedValues = updateInitialValues();
    }
    if (!isEqual(prevHiddenIndices, props.hideIndices)) {
      updateHideIndices(_trackedValues);
    }
    return function cleanup() { };
  }, [props.hideIndices, props.initialValues]);

  React.useEffect(() => {
    const disabled = props.disabled || props.hideIndices.length === 2;
    updateSliderState({ disabled });
    return function cleanup() { };
  }, [props.disabled]);

  function updateHideIndices(trackedValues) {
    // Change slider values
    const nulledValues = valuesWithHiddenAsNull(trackedValues);
    const sliderValues = stripNullValues(nulledValues);

    // Maybe change trackDirection
    let slideDirection = 'inverted';
    if (nulledValues[0] !== null && nulledValues[1] === null) {
      slideDirection = 'normal';
    }

    //  Maybe move an offside slider control.
    if (props.hideIndices.length === 0 && prevHiddenIndices.length === 1) {
      // left value becomes visible.
      if (prevHiddenIndices[0] === 0) {
        if (trackedValues[0] > trackedValues[1]) {
          sliderValues[0] = Math.max(trackedValues[1] - props.offsideValueOffset, 0);
        }
      }
      // right value becomes visible.
      else if (trackedValues[1] < trackedValues[0]) {
        sliderValues[1] = Math.min(trackedValues[0] + props.offsideValueOffset, 100);
      }
      updateTrackedValues(sliderValues);
    }

    const disabled = props.disabled || props.hideIndices.length === 2;
    updateSliderState({ value: sliderValues, track: slideDirection, disabled });
    setPrevHiddenIndices(props.hideIndices);
  }

  function updateInitialValues() {
    const values = toggleInverted(props.initialValues);
    updateHideIndices(values);
    setPrevInitialValues(props.initialValues);
    return updateTrackedValues(values);
  }

  /**
   * Update the slide with new value.
   * Trigger CSS redraw (set visible values)
   * @param {*} event
   * @param {*} newValue
   * @param {*} other
   */
  const handleSlide = (event, newValue, other) => {
    updateSliderState({ value: newValue });
    const changes = updateTrackedValues(newValue);
    props.onChange(toggleInverted(changes));
  };

  let labelFormatters;
  if (props.sliderProps && props.sliderProps.valueLabelFormat) {
    labelFormatters = [props.sliderProps.valueLabelFormat, (x) => props.sliderProps.valueLabelFormat(100 - x)];
  } else {
    labelFormatters = [(x) => x, (x) => 100 - x];
  }
  const labelProps = {
    valueFormaters: labelFormatters,
    indexOverride: props.hideIndices.includes(0) ? 1 : null,
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item>
        <Typography variant="bodyLight" wordBreak="all" component="div">
          {props.startLabel}
        </Typography>
      </Grid>
      <Grid item xs>
        { props.isLoading
          ? <SkeletonLoader width={400} height={4} rounded />
          : (
            <Slider
              {...props.sliderProps}
              {...sliderPropsState}
              onChange={handleSlide}
              valueLabelDisplay="on"
              ValueLabelComponent={(extProps) => <ValueLabelComponent {...extProps} {...labelProps} colors={props.colors.secondary} />}
              classes={{
                root: classes.sliderRoot,
                rail: classes.rail,
                thumbColorPrimary: classes.thumbIndicator,
                track: classes.track,
                trackInverted: classes.trackInverted,
              }}
            />
          )}

      </Grid>
      <Grid item>
        <Typography variant="bodyLight" wordBreak="all" component="div">
          {props.endLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

DuoSlider.propTypes = {
  disabled: PropTypes.bool,
  hideIndices: PropTypes.arrayOf(PropTypes.number),
  offsideValueOffset: PropTypes.number,

  colors: PropTypes.shape({
    primary: PropTypes.arrayOf(PropTypes.string),
    secondary: PropTypes.arrayOf(PropTypes.string),
  }),
  initialValues: PropTypes.array,
  onChange: PropTypes.func,
  sliderProps: PropTypes.object,
  isLoading: PropTypes.bool,

  endLabel: PropTypes.string,
  startLabel: PropTypes.string,
};

DuoSlider.defaultProps = {
  isLoading: false,
  hideIndices: [],
  offsideValueOffset: 10,
  colors: {
    primary: ['#1883ef', '#6bc818'],
    secondary: ['#1883ef', '#6bc818'],
  },
  disabled: false,
  initialValues: [0, 0],
  onChange: () => {},
  sliderProps: { track: 'normal' },
  endLabel: '',
  startLabel: '',
};

export default DuoSlider;
