import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import TextField from '@unity/react-forms/textfield';
import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';
import configurationDataAPI from '../../../data/configuration-data-api';
import { makeCancelable } from '../../../helpers/cancellable-promise';
import ErrorText from '../../../app-wide-controls/error-text';
import { ResponseError } from '../../../data/api-cache';

const FIELD_HOURS = 'hours';
const FIELD_MINUTES = 'minutes';
const FIELD_SECONDS = 'seconds';

const validationSchema = yup.object().shape({
  [FIELD_HOURS]: yup.number().positive().integer()
    .min(0, 'Hours must be a positive number'),
  [FIELD_MINUTES]: yup.number().positive().integer()
    .min(0, 'Minutes must be a positive number'),
  [FIELD_SECONDS]: yup.number().positive().integer()
    .min(0, 'Seconds must be a positive number'),
});


/**
 * Displays textfield inputs for Hours, Minutes, Seconds.
 * Retrieves and displays initial data the 'dataID' property which
 * is tied to reading from the Accelerator's configuration settings.
 *
 * A modification of Unity's frontend library example:
 * https://unitytech.github.io/unity-frontend-lib-docs/docs/@unity/react-forms/0.6.0/index.html?page=components/card-form-manager
 * Slack: #unity-frontend-lib @joona @maciej
 * @param {} props
 */
const DurationEditor = (props) => {
  const [uiContent, setUIContent] = useState(null);
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [fieldValuesState, setFieldValuesState] = useState(null);
  let _fieldValues = null;

  function setIsLoading(isLoading) {
    setIsLoadingState(isLoading);
    if (props.isLoadingListener) {
      props.isLoadingListener(isLoading);
    }
  }

  useEffect(() => {
    setIsLoading(true);

    let fetchRequest;
    (async function () {
      try {
        fetchRequest = makeCancelable(configurationDataAPI.valuesForKeys(props.dataID));
        const { [props.dataID]: data } = await fetchRequest.promise;
        const timeInfo = data.toViewModel();

        const FormikProps = {
          initialValues: {
            hours: timeInfo.hours,
            minutes: timeInfo.minutes,
            seconds: timeInfo.seconds,
          },
        };

        const content = (
          <Formik
            onSubmit={() => {}}
            {...FormikProps}
            validateOnChange
            validationSchema={validationSchema}
          >

            <>
              <Field
                name="hours"
                label="Hours"
                margin="normal"
                validate={(input) => onEdit(input, FIELD_HOURS)}
                showErrorOnChange
                component={TextField}
              />
              <Field
                name="minutes"
                label="Minutes"
                margin="normal"
                showErrorOnChange
                validate={(input) => onEdit(input, FIELD_MINUTES)}
                component={TextField}
              />
              <Field
                name="seconds"
                label="Seconds"
                margin="normal"
                showErrorOnChange
                validate={(input) => onEdit(input, FIELD_SECONDS)}
                component={TextField}
              />
            </>
          </Formik>

        );
        setIsLoading(false);
        setUIContent(content);
      } catch (error) {
        const message = ResponseError.message(error);
        const content = (_props) => (<ErrorText text={message} />);
        setIsLoading(false);
        setUIContent(content);
      }
    }());
    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
    };
  }, [props.dataID]);


  function onEdit(text, fieldName) {
    updateEditedData(text, fieldName);
    props.onEditListener({ [props.dataID]: editedData() });
  }

  /**
     * Wrapper to read from useState or from a local member holding the same
     * value(s). This enables reliable immediate use of changed data.
     * Values in useState hook are asynchronously updated and won't be
     * available till after (or perhaps during) next render.
     */
  function editedData() {
    if (_fieldValues) {
      return _fieldValues;
    }
    return fieldValuesState;
  }

  function updateEditedData(data, field) {
    const existingData = editedData();
    let updatedData = {};
    if (existingData) {
      updatedData = { ...existingData };
    }
    updatedData = { ...updatedData, [field]: data };
    _fieldValues = updatedData;
    setFieldValuesState(updatedData);
  }

  function UI() {
    if (isLoadingState) {
      return (<SkeletonLoader width={250} height={20} gutterBottom="small" rounded />);
    }
    return uiContent;
  }

  return (
    <>
      {UI()}
    </>
  );
};
const styles = ({ unityTypography }) => ({
  caption: unityTypography.caption,
});


DurationEditor.propTypes = {
  dataID: PropTypes.string,
  onEditListener: PropTypes.func.isRequired,
  isLoadingListener: PropTypes.func,
};

export default withStyles(styles)(DurationEditor);
