// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export const makeCancelable = (promise) => {
  let isCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => {
      if (!isCancelled) {
        resolve(val);
      }
      // else: No action. Promise not returned because caller cancelled.
    }).catch((error) => {
      if (!isCancelled) {
        reject(error);
      }
      // else: No action. Promise not returned because caller cancelled.
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCancelled = true;
    },
  };
};
