import appAxios from './app-axios';
import agentHealthInstance from './agent-health';
import { locationPort } from '../helpers/window-helpers';
import { HTTPPort } from './config-keys';
import ConfigurationDataAPI from './configuration-data-api';
import { ResponseError } from './api-cache';

/**
 * Queries the API if the accelerator requires a restart.
 * Requests a restart.
 * Updates callbacks if restart status changes.
 */
class AcceleratorRestart {
  constructor() {
    this._hasPendingRestart = false;
    this._hasPendingRestartReasons = [];
    this._onRestartChangesCallbacks = [];
  }

  get hasPendingRestart() {
    return this._hasPendingRestart;
  }

  checkRestartNeeded() {
    const self = this;
    appAxios.get('/restart').then((response) => {
      self.setRestartNeeded(response.data.restartRequired, response.data.restartReasons);
    })
      .catch((response) => {
        // Unhandled.
      });
  }

  setRestartNeeded(isNeeded, reasons) {
    if (this._hasPendingRestart !== isNeeded) {
      this._hasPendingRestart = isNeeded;
      this._hasPendingRestartReasons = reasons;
      const self = this;
      this._onRestartChangesCallbacks.forEach((callback) => {
        callback(self._hasPendingRestart, self._hasPendingRestartReasons);
      });
    }
  }

  addRestartChangeCallback(callback) {
    this._onRestartChangesCallbacks.push(callback);
  }

  removeRestartChangeCallback(callback) {
    const index = this._onRestartChangesCallbacks.indexOf(callback);
    if (index < 0) {
      console.error('[ConfigurationDataAPI:removeRestartChangeCallback] No callback found');
      return;
    }
    this._onRestartChangesCallbacks.splice(index, 1);
  }

  /**
   * @param {function({didRestart:bool, port:Integer}, error:string} onComplete
   */
  async restartAccelerator(onComplete) {
    onComplete = onComplete || (() => {});
    let port = locationPort();

    if (!this._hasPendingRestart) {
      onComplete({ didRestart: false, port });
    }

    const self = this;
    try {
      const { [HTTPPort]: { data } } = await ConfigurationDataAPI.valuesForKeys(HTTPPort);
      if (data !== 0) {
        port = data;
      }

      await appAxios.post('/restart');
      self._hasPendingRestart = false;
      self._hasPendingRestartReasons = [];
      agentHealthInstance.notifyWhenAgentRunning(port).then(() => {
        onComplete({ didRestart: true, port }, null);
      });
    } catch (error) {
      const message = ResponseError.message(error);
      onComplete({ didRestart: false, port: -1 }, message);
    }
  }
}


const restartMonitor = new AcceleratorRestart();
export default restartMonitor;
