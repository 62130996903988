import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withOpenability from '@unity/react-components/hoc/with-openability';
import flow from 'lodash/flow';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ModalTitle from '@unity/react-components/modal-title';
import Banner from '@unity/react-components/banner';
import ErrorIcon from '@material-ui/icons/Error';
import ModalContent from '@unity/react-components/modal-content';
import ModalActions from '@unity/react-components/modal-actions';
import Typography from '@unity/react-components/typography';
import Button from '@unity/react-components/button';
import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';
import { BUTTONS_SAVE, BUTTONS_CLOSE } from '../../../helpers/constants';


const ModalLayout = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function onSave() {
    setIsSaving(true);
    props.onExit(true);
  }

  function onCancel() {
    props.onExit(false);
  }

  function onContentSentLoading(contentIsLoading) {
    setIsLoading(contentIsLoading);
  }

  useEffect(() => {
    if (props.isOpen) {
      props.dialogOpenable.handleOpen();
      if (props.content) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      props.dialogOpenable.handleClose();
      setIsSaving(false);
      setIsLoading(false);
    }
    return function cleanup() {
    };
  }, [props.isOpen]);

  useEffect(() => {
    if (props.showError) {
      setIsSaving(false);
    }
    return function cleanup() {
    };
  }, [props.showError]);

  useEffect(() => {
    if (props.content) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    return function cleanup() {
    };
  }, [props.content]);

  function titleElement() {
    if (isLoading) {
      return (<SkeletonLoader width={300} height={20} gutterBottom="small" rounded />);
    }
    return (
      <Typography variant="titleBig" gutterBottom="small">
        {props.title}
      </Typography>
    );
  }

  function subTitleElement() {
    if (props.subTitle) {
      return (
        <Typography variant="caption">
          {props.subTitle}
        </Typography>
      );
    }
    return null;
  }

  function modalContentUI() {
    if (props.isLoading || props.content === null) {
      return (<SkeletonLoader width={300} height={20} gutterBottom="small" rounded />);
    }
    return props.content;
  }

  function buttonsUI() {
    if (props.buttonLayout === BUTTONS_SAVE) {
      return (
        <>
          <Button onClick={onCancel} disabled={isSaving}>Cancel</Button>
          <Button color="primary" onClick={onSave} loading={isSaving} disabled={isLoading}>Save</Button>
        </>
      );
    } if (props.buttonLayout === BUTTONS_CLOSE) {
      return (
        <>
          <Button onClick={onCancel}>Close</Button>
        </>
      );
    }
  }

  return (
    <>
      <Dialog
        classes={{ paperWidthSm: props.classes.dialogOverride }}
        open={props.dialogOpenable.open}
        onClose={() => {}}
      >
        <ModalTitle>
          {titleElement()}
          {subTitleElement()}
        </ModalTitle>
        <Banner
          inset
          animate
          dividerBottom
          open={props.showError}
          state="error"
          content={(
            <>
              <ErrorIcon color="error" />
              <Typography variant="body">
                {props.errorMessage}
              </Typography>
            </>
                    )}
        />
        <ModalContent>
          {modalContentUI()}
        </ModalContent>
        <ModalActions>
          {buttonsUI()}
        </ModalActions>
      </Dialog>
    </>
  );
};

ModalLayout.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onExit: PropTypes.func, // f(modalUserContent, shouldSave)
  errorMessage: PropTypes.node,
  showError: PropTypes.bool,
  content: PropTypes.element,
  buttonLayout: PropTypes.string, // see buttons constants for options.
};

ModalLayout.defaultProps = {
  isOpen: false,
  title: '',
  errorMessage: null,
  showError: false,
  content: null,
  buttonLayout: BUTTONS_SAVE,
};

const styles = () => ({
  dialogOverride: {
    minWidth: '300px',
  },
});

export default flow(withStyles(styles), withOpenability('dialogOpenable', 'errorOpenable'))(ModalLayout);
