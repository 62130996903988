import React, { useState, useEffect } from 'react';
import flow from 'lodash/flow';
import { withStyles } from '@material-ui/core/styles';
import _map from 'lodash/map';

import SmartTable from '@unity/react-components/smart-table/smart-table';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import SearchInput from '@unity/react-labs/search-input';
import ItemPlaceholder from '@unity/react-components/item-placeholder';
import createSearcher from './search-helper';


function TableWithSearch(props) {
  const [searchText, setSearchText] = useState('');
  const [isSearchDirty, setIsSearchDirty] = useState(false);
  const [tableRows, setTableRows] = useState(props.rows);

  useEffect(() => {
    if (isSearchDirty) {
      if (props.searchCallback) {
        props.searchCallback(searchText);
      } else if (searchText.length === 0) {
        setTableRows(props.rows);
      } else {
        const keys = _map(props.columns, 'property'); // Appears to be the 'standard' key.
        const rowSearcher = createSearcher({ searchKeys: keys });
        const searchResults = rowSearcher(props.rows, searchText);
        setTableRows(searchResults);
      }
      setIsSearchDirty(false);
    }
    // Respond to prop changes in caller.
    // useState should be ignoring same value assignments.
    else {
      setTableRows(props.rows);
    }
  });

  function onSearchChange(text) {
    setIsSearchDirty(true);
    setSearchText(text);
  }

  function insertSearchBar() {
    if (props.showSearch) {
      return (
        <ListItem divider dense className={props.classes.listItemOverride}>
          <SearchInput
            className={props.classes.searchControlOverride}
            value={searchText}
            placeholder="Looking for someone?"
            margin="normal"
            onChange={(e) => onSearchChange(e.target.value)}
            onClear={(e) => onSearchChange('')}
          />
        </ListItem>
      );
    }
  }

  function tablePlaceholder() {
    const visibleRowCount = 10;
    const { fetchError, isLoading, rows } = props;
    if (isLoading) {
      return <ItemPlaceholder itemCount={visibleRowCount} />;
    }
    if (fetchError) {
      return <div>Error</div>;
    }
    if (rows.length === 0) {
      return <div>No Table Data</div>;
    }
    if (visibleRowCount === 0) {
      return <div>No Search Results</div>;
    }
    return null;
  }

  return (
    <Card className={props.classes.cardOverride}>
      {insertSearchBar()}
      <SmartTable
        rowKey="name"
        rows={tableRows}
        labelRowsPerPage="Rows per page"
        columns={props.columns}
        initialRowsPerPage={10}
        itemPlaceholder={tablePlaceholder()}
        paginate={props.showPagination}
      />
    </Card>
  );
}

const styles = ({ palette, spacing }) => ({
  cardOverride: {
    maxWidth: '9000px',
    '@media (max-width: 959.95px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  searchIcon: {
    color: palette.text.disabled,
    marginRight: spacing(1),
  },
  input: {
    height: spacing(6),
  },
  searchControlOverride: {
    border: 0,
    margin: 0,
  },
  listItemOverride: {
    padding: 0,
  },
});

export default flow(withStyles(styles))(TableWithSearch);
