import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: (props) => props.backgroundColor,
    color: 'rgba(0, 0, 0, 0.8)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value, disabled } = props;
  const popperRef = React.useRef(null);
  const classes = useStyles({
    backgroundColor: props.colors[activeIndex()],
  });

  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  function activeIndex() {
    let { index } = props;
    if (index === 0 && props.indexOverride !== null) {
      index = props.indexOverride;
    }
    return index;
  }

  function title(value) {
    let _title = value;
    if (props.valueFormaters && props.valueFormaters.length > 0) {
      _title = props.valueFormaters[activeIndex()](value);
    } else if (props.valueLabelFormat) {
      _title = props.valueLabelFormat(value);
    }
    return _title;
  }

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={!disabled}
      TransitionProps={{
        timeout: 0,
      }}
      enterTouchDelay={0}
      placement="top"
      title={title(value)}
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  valueFormaters: PropTypes.arrayOf(PropTypes.func),
  indexOverride: PropTypes.number,
  colors: PropTypes.arrayOf(PropTypes.string),
};

ValueLabelComponent.defaultProps = {
  valueFormaters: null,
  indexOverride: null,
};

export default withStyles({ withTheme: true })(ValueLabelComponent);
