// TODO: The 'parsePrometheusTextFormat' import below is a modified file of one found in a node module.
// The original code is limiting.  Referencing this modified (and possibly outdated) version should be
// addressed in code maintenance. Ideally, changes and tests would be contributed back to the source (see below).
// It's use is prototypical at this point.
// https://github.com/yunyu/parse-prometheus-text-format
// local path when node_modules present: /internal/dashboard/node_modules/parse-prometheus-text-format/README.
import numeral from 'numeral';
import parsePrometheusTextFormat from '../npm-package-src-override/parse-prometheus-text-format.umd';
import APICache from './api-cache';


class MetricsData extends APICache {
  constructor() {
    super('/metrics');
  }

  areAllMetricsLoaded() {
    return this._areAllMetricsLoaded;
  }

  _onDataChange(data) {
    this._data = parsePrometheusTextFormat(data);
  }

  _onKeysRequestSuccess(keys, successFunc, errorFunc) {
    const result = this._metricsForKeys(keys);
    successFunc(result);
  }

  _onKeysRequestError(errorResponse, errorFunc, successFunc) {
    errorFunc(errorResponse);
  }

  _metricsForKeys(keys) {
    const results = {};
    keys = Array.isArray(keys) ? keys : [keys];
    const self = this;
    keys.forEach((key) => {
      results[key] = self._metricForKey(key);
    });

    return results;
  }

  _metricForKey(key) {
    if (!this._data[key]) {
      return;
    }

    const { metrics } = this._data[key];
    if (!metrics || metrics.length === 0) {
      console.log('Problem! [MetricsData :_metricForKey] no data in metric: ', key);
    }

    // Keys needing full result.
    if (key === 'uta_agent_info') {
      return metrics;
    }

    // Pick out the target value and convert/simplify.
    let result = [];
    const self = this;
    metrics.forEach((metric) => {
      result.push(self._toValue(metric.value));
    });

    // Don't return an array for single item.
    if (result.length === 1) {
      result = result[0];
    }
    return result;
  }

  _toValue(strValue) {
    return numeral(strValue).value();
  }
}

const metricsDataAPI = new MetricsData();
export default metricsDataAPI;
