import React from 'react';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@unity/react-components/typography';
import PropTypes from 'prop-types';
import DuoSlider from '../duo-slider';
import Cell from '../disk-cell';
import CheckboxSlider from '../checkbox-slider';
import * as configDataIDs from '../../../data/config-keys';
import metricsHealthInstance from '../../../data/metrics-health';
import { makeCancelable } from '../../../helpers/cancellable-promise';
import { fetchData } from '../../../data/data-getter';
import { readableBytes } from '../../../helpers/readable-text';
import * as constants from '../../../helpers/constants';
import ErrorText from '../../../app-wide-controls/error-text';
import { ResponseError } from '../../../data/api-cache';

const styles = () => ({
  root: {
    width: '100%',
  },
  description: {
    marginBottom: 26,
  },
  divider: {
    marginBottom: 20,
    marginTop: 20,
  },
  cellHeader: {
    marginBottom: 0,
  },
  checkboxes: {
    marginBottom: 12,
  },
  dividerBetweenCustomSliders: {
    marginBottom: 30,
    marginTop: 30,
  },
});


const SECTION_BASIC_ID = 'SECTION_BASIC_ID';
const SECTION_CUSTOM_ID = 'SECTION_CUSTOM_ID';
const MAX_ELEVATION = 6;
const MIN_ELEVATION = 1;
const BYTES_STEP = 0.1;
const EMPTY_CONFIG_VALUE = -1;

const DiskEditor = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [selectionState, setSelectionState] = React.useState({
    selections: {
      [SECTION_BASIC_ID]: false,
      [SECTION_CUSTOM_ID]: true,
    },
  });

  const [colors, setColors] = React.useState({
    primary: [props.theme.palette.primary.dark, props.theme.palette.success.dark],
    secondary: ['#aad1f7', '#cfeaa9'],
  });

  const defaultData = { slider: 0, on: false };
  const [data, setData] = React.useState({
    totalBytes: 0,
    basicPercent: { ...defaultData },
    percentToUse: { ...defaultData },
    percentFree: { ...defaultData },
    bytesToUse: { ...defaultData },
    bytesFree: { ...defaultData },
  });

  function elevation(sectionID) {
    return selectionState.selections[sectionID] ? MAX_ELEVATION : MIN_ELEVATION;
  }

  React.useEffect(() => {
    // Metrics takes some time to fully load since the server take a minute
    // to write it to disk when first starting up.
    const metricsReady = makeCancelable(metricsHealthInstance.notifyWhenMetricsReady());
    let fetchRequest;

    (async function () {
      try {
        await metricsReady.promise;
        fetchRequest = makeCancelable(fetchData(
          [configDataIDs.CacheMinFreePercent, configDataIDs.CacheMinFreeBytes,
            configDataIDs.CacheMaxUsedBytes, configDataIDs.CacheMaxUsedPercent],
          [constants.METRIC_KEY_DISK_TOTAL, constants.METRIC_KEY_SYSTEM_BYTES_USED, constants.METRIC_KEY_DISK_USED_BY_AGENT],
        ));
        const values = await fetchRequest.promise;
        const { configValues, metricValues } = values;
        const isValid = (data) => data > EMPTY_CONFIG_VALUE;
        const sliderValue = (data) => (isValid(data) ? data : 0);
        const bytesToSliderValue = (bytes, totalBytes) => {
          const percent = (bytes / totalBytes) * 100;
          const rounding = Math.round(percent / BYTES_STEP) * BYTES_STEP;
          return rounding;
        };

        const { [configDataIDs.CacheMaxUsedPercent]: maxPercent,
          [configDataIDs.CacheMinFreePercent]: minPercent,
          [configDataIDs.CacheMaxUsedBytes]: maxBytes,
          [configDataIDs.CacheMinFreeBytes]: minBytes } = configValues;

        const results = {
          data: {
            configValues, metricValues,
          },
          totalBytes: metricValues[constants.METRIC_KEY_DISK_TOTAL],
          basicPercent: {
            original: maxPercent.data,
            slider: sliderValue(maxPercent.data),
            on: isValid(maxPercent.data),
          },
          percentToUse: {
            original: maxPercent.data,
            slider: sliderValue(maxPercent.data),
            on: isValid(maxPercent.data),
          },
          percentFree: {
            original: minPercent.data,
            slider: sliderValue(minPercent.data),
            on: isValid(minPercent.data),
          },
          bytesToUse: {
            original: maxBytes.data,
            slider: bytesToSliderValue(sliderValue(maxBytes.data), metricValues[constants.METRIC_KEY_DISK_TOTAL]),
            on: isValid(maxBytes.data),
          },
          bytesFree: {
            original: minBytes.data,
            slider: bytesToSliderValue(sliderValue(minBytes.data), metricValues[constants.METRIC_KEY_DISK_TOTAL]),
            on: isValid(minBytes.data),
          },
        };

        const showBasicEditing = !results.percentFree.on && !results.bytesToUse.on && !results.bytesFree.on;
        const selections = {
          [SECTION_BASIC_ID]: showBasicEditing,
          [SECTION_CUSTOM_ID]: !showBasicEditing,
        };
        setSelectionState((prev) => ({ ...prev, selections }));
        setData((prev) => ({ ...prev, ...results }));
        setIsLoading(false);
      } catch (error) {
        const message = ResponseError.message(error);
        setIsLoading(false);
        setErrorMessage(message);
        setShowError(true);
      }
    }());

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
      metricsReady.cancel();
    };
  }, []);

  const { classes } = props;

  /**
   * Radio button clicks only send events when click is on
   * an unselected button.
   * @param {} id
   */
  function onCellSelection(id) {
    // Toggle values.
    const selections = {
      [SECTION_BASIC_ID]: !selectionState.selections[SECTION_BASIC_ID],
      [SECTION_CUSTOM_ID]: !selectionState.selections[SECTION_CUSTOM_ID],
    };
    setSelectionState((prev) => ({ ...prev, selections }));

    // Treat toggling between basic/custom sections as data
    // selections by the user, since they have the option to save
    // the given values.
    props.onEditListener(configChangesForSection(id));
  }

  /**
   *
   * @param {*} sectionID
   * @param {*} optionalLatestData Provide within a state setting loop
   *                              (i.e. latest useState is not yet available)
   */
  function configChangesForSection(sectionID, optionalLatestData) {
    const _data = optionalLatestData || data;
    let changes;
    if (sectionID === SECTION_BASIC_ID) {
      changes = {
        [configDataIDs.CacheMaxUsedPercent]: _data.basicPercent.slider,
        [configDataIDs.CacheMinFreePercent]: EMPTY_CONFIG_VALUE,
        [configDataIDs.CacheMaxUsedBytes]: EMPTY_CONFIG_VALUE,
        [configDataIDs.CacheMinFreeBytes]: EMPTY_CONFIG_VALUE,
      };
    } else {
      changes = {
        [configDataIDs.CacheMaxUsedPercent]: _data.percentToUse.on ? _data.percentToUse.slider : EMPTY_CONFIG_VALUE,
        [configDataIDs.CacheMinFreePercent]: _data.percentFree.on ? _data.percentFree.slider : EMPTY_CONFIG_VALUE,
        [configDataIDs.CacheMaxUsedBytes]: _data.bytesToUse.on ? _data.bytesToUse.slider : EMPTY_CONFIG_VALUE,
        [configDataIDs.CacheMinFreeBytes]: _data.bytesFree.on ? _data.bytesFree.slider : EMPTY_CONFIG_VALUE,
      };
    }
    return changes;
  }

  function convertFromSliderValueToBytes(value) {
    return Math.floor(data.totalBytes * (value / 100));
  }

  /**
   *
   * @param {
   * {
   *    checked: Array.Bool,
   *    slider: Array.Num,
   *   }
   * }
   */
  function handleCustomPercentChange(changes) {
    const percentToUse = { ...data.percentToUse };
    percentToUse.slider = changes.slider[0];
    percentToUse.on = changes.checked[0];

    const percentFree = { ...data.percentFree };
    percentFree.slider = changes.slider[1];
    percentFree.on = changes.checked[1];

    const _data = { ...data, percentToUse: { ...percentToUse }, percentFree: { ...percentFree } };
    setData(_data);
    props.onEditListener(configChangesForSection(SECTION_CUSTOM_ID, _data));
  }

  function handleCustomBytesChange(changes) {
    const bytesToUse = { ...data.bytesToUse };
    bytesToUse.slider = changes.slider[0];
    bytesToUse.on = changes.checked[0];

    const bytesFree = { ...data.bytesFree };
    bytesFree.slider = changes.slider[1];
    bytesFree.on = changes.checked[1];

    const _data = { ...data, bytesToUse: { ...bytesToUse }, bytesFree: { ...bytesFree } };
    setData(_data);
    props.onEditListener(configChangesForSection(SECTION_CUSTOM_ID, _data));
  }
  /**
   * Override all other changes.
   * @param {*} value
   */
  const handleBasicSliderChange = (value) => {
    const sliderValue = value[0];
    // To keep 'percentToUse' in the Custom in sync with Basic changes
    // update data.percentToUse.slider here as well.
    // Otherwise, the intentional is to not show relationships between in the sections.
    const _data = { ...data, basicPercent: { ...data.basicPercent, slider: sliderValue } };
    setData(_data);
    props.onEditListener(configChangesForSection(SECTION_BASIC_ID, _data));
  };

  return (
    <div className={classes.root}>
      <Paper elevation={elevation(SECTION_BASIC_ID)}>
        <Cell
          title="Basic"
          isSelected={selectionState.selections[SECTION_BASIC_ID]}
          onRadioClick={onCellSelection}
          identifier={SECTION_BASIC_ID}
        >
          <Typography variant="bodyLight" wordBreak="word" component="div" className={classes.description}>
            Max Usage
          </Typography>

          { showError
            ? <ErrorText text={errorMessage} />
            : (
              <DuoSlider
                initialValues={[data.basicPercent.slider, 0]}
                hideIndices={[1]}
                onChange={handleBasicSliderChange}
                sliderProps={{
                  valueLabelFormat: (n) => `${n}%`,
                }}
                isLoading={isLoading}
                disabled={!selectionState.selections[SECTION_BASIC_ID]}
                colors={{ ...colors }}
              />
            )}

        </Cell>
      </Paper>
      <div variant="middle" className={classes.divider} />
      <Paper variant="outlined" elevation={elevation(SECTION_CUSTOM_ID)}>
        <Cell
          title="Custom"
          className={{ root: classes.cellHeader }}
          isSelected={selectionState.selections[SECTION_CUSTOM_ID]}
          onRadioClick={onCellSelection}
          identifier={SECTION_CUSTOM_ID}
        >
          <Typography variant="bodyLight" wordBreak="word" component="div" className={classes.description}>
            {'The accelerator will utilize as much disk space as granted.  \n'
            + 'Unchecked values removes restrictions. \n'
            + '\'Free\' values alongside usage values are useful when space is shared with '
            + 'other resources/processes; the accelerator obeys free disk restrictions even before reaching capacity.'}
          </Typography>

          { showError
            ? <ErrorText text={errorMessage} />
            : (
              <>
                <CheckboxSlider
                  labels={['Percent available to use', 'Percent to remain free']}
                  disabled={!selectionState.selections[SECTION_CUSTOM_ID] || isLoading}
                  duoSliderProps={{
                    initialValues: [data.percentToUse.slider, data.percentFree.slider],
                    disabled: !selectionState.selections[SECTION_CUSTOM_ID],
                    colors: { ...colors },
                    isLoading,
                  }}
                  sliderProps={{ valueLabelFormat: (n) => `${n}%` }}
                  initialCheckedValues={[data.percentToUse.on, data.percentFree.on]}
                  onChange={handleCustomPercentChange}
                />
                <div variant="middle" className={classes.dividerBetweenCustomSliders} />
                <CheckboxSlider
                  labels={['Bytes available to use', 'Bytes to remain free']}
                  disabled={!selectionState.selections[SECTION_CUSTOM_ID] || isLoading}
                  duoSliderProps={{
                    initialValues: [data.bytesToUse.slider, data.bytesFree.slider],
                    disabled: !selectionState.selections[SECTION_CUSTOM_ID],
                    colors: { ...colors },
                    isLoading,
                  }}
                  sliderProps={{ step: BYTES_STEP, valueLabelFormat: (n) => `${readableBytes(convertFromSliderValueToBytes((n)))}` }}
                  initialCheckedValues={[data.bytesToUse.on, data.bytesFree.on]}
                  onChange={handleCustomBytesChange}
                />
              </>
            )}
        </Cell>
      </Paper>
    </div>
  );
};

DiskEditor.propTypes = {
  // Set by the modal handler to pass config data for saving.
  onEditListener: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(DiskEditor);
