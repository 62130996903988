import React from 'react';
import Button from '@unity/react-components/button';
import PropTypes from 'prop-types';

/**
 * Extends button by adding a generic callback to match
 * the 'onChange' interface to other web controls.
 *
 * 'OnChange' returns the assigned data.
 * @param {*} props
 */
const OnChangeButton = (props) => {
  function onClick(/* event, value */) {
    if (props.onChange) {
      props.onChange(props.data);
    }
  }

  return (
    <Button size="small" color="primary" {...props} onClick={onClick} />
  );
};

OnChangeButton.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.any,
};

export default (OnChangeButton);
