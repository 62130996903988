/**
 *  Keeps track of the Open and Closed state for a given list of unique identifiers (objects).
 *
 *  Additionally, it is useful for:
 *      - Retrieving the list of data for a closed or open parent (i.e. list section).
 *      - Adding or Removing to respond to data set changes (e.g. Data updates).
 *      - Applying recursive rules that affect subtrees (e.g. Closing a parent closes all nested list/subtrees).
 *
 */
export class ExpandingTreeDataHandler {
  constructor() {
    // Is inactive and contains no data.
    this._rootnode;

    // Quick lookup to find data by its associated key/id.
    // Keys/IDs are required from the caller when building and updating the tree.
    this._idToNode = {};

    // Whether close and open events affect subtrees/nested lists.
    // TRUE:    Closing a (sub)tree closes all descendants (subtrees/sublists).
    // FALSE:   (Re)opening a (sub)tree keeps nested subtrees in their previous open state.
    //          (Nested subtrees/sublists are not closed when a parent tree is closed)
    this.cascadeExpand = false;
  }

  /**
     *  @param idlist {Array} Descriptions of all objects in the order they
     *  are when completely expanded. e.g.
     *      {
     *          id:     {*} unique
     *          depth:  {Number} Integer.  Determines the relationship between adjacent
     *                  items.  When:
     *                  - less than the previous index: current index is an uncle/aunt.
     *                  - equal to previous index: current index is a sibling.
     *                  - greater than previous index: current index is a child.
     *          ...     Anything extra is ignored but can be provided and used as storage
     *                  to be accessed when retrieving nodes.
     *      }
     */
  loadFromDepthList(depthlist) {
    this._rootnode = ExpandingTreeDataHandler.treeFromDepthList(depthlist, this._idToNode)[0];
  }

  loadFromNestedArrays(nestedArrays, keyForArrays, keyForItemID) {
    const keys = {
      [PROPERTY_NAME_ID]: keyForItemID,
      [PROPERTY_NAME_LIST]: keyForArrays,
    };

    const dummy_root = {
      [keyForArrays]: nestedArrays,
      [keyForItemID]: this.defaultRootID(),
    };
    this._rootnode = ExpandingTreeDataHandler.buildNode(dummy_root, this._idToNode, keys);
  }

  defaultRootID() {
    return 'ROOT_NODE_ExpandingTreeDataHandler';
  }

  /**
     * Returns a TreeNode when id matches one supplied in loading or updating the list.
     * @param {*} id
     */
  nodeForID(id) {
    return this._idToNode[id];
  }

  insert(idlist, parentNode) {
    const roots = ExpandingTreeDataHandler.treeFromDepthList(idlist, this._idToNode);
    Array.prototype.push.apply(parentNode.children, roots);
  }

  closeNode(node, nodeCallback) {
    node.isOpen = false;
    if (nodeCallback) {
      nodeCallback(node);
    }
  }

  openNode(node, nodeCallback) {
    node.isOpen = true;
    if (nodeCallback) {
      nodeCallback(node);
    }
  }

  closeTree(parentNode, nodeCallback) {
    this.closeNode(parentNode, nodeCallback);
    this.closeSubTree(parentNode, nodeCallback);
  }

  closeSubTree(parentNode, nodeCallback) {
    let childNode;
    for (let i = 0; i < parentNode.children.length; i++) {
      childNode = parentNode.children[i];
      this.closeTree(childNode, nodeCallback);
    }
  }

  openTree(parentNode, nodeCallback) {
    this.openNode(parentNode, nodeCallback);

    let childNode;
    for (let i = 0; i < parentNode.children.length; i++) {
      childNode = parentNode.children[i];
      if (this.cascadeExpand) {
        const wasOpen = childNode.isOpen;
        this.openNode(childNode, nodeCallback);

        // Remembers previous state.
        if (wasOpen) {
          this.openTree(childNode, nodeCallback);
        }
      }
    }
  }

  /**
     *
     * @param node TreeNode (Optional.)
     */
  parentNodesUnder(node) {
    const nodes = [];
    let startNode = node;
    if (!node) {
      startNode = this._rootnode;
    }
    this._parentNodesUnder(startNode, nodes);
    return nodes;
  }

  _parentNodesUnder(node, bag) {
    if (node.isLeaf()) {
      return;
    }
    bag.push(node);
    for (let i = 0; i < node.children.length; i++) {
      this._parentNodesUnder(node.children[i], bag);
    }
  }

  static treeFromDepthList(depthList, lookupMap) {
    let previousNode;
    let treeNode;
    const roots = [];
    let item;

    for (let i = 0; i < depthList.length; i++) {
      item = depthList[i];
      treeNode = new TreeNode();
      treeNode.ID = item[this.PROPERTY_NAME_ID];
      treeNode.content = item;
      if (lookupMap) {
        lookupMap[treeNode.ID] = treeNode;
      }
      if (!previousNode) {
        previousNode = treeNode;
        roots.push(treeNode);
      } else {
        // move up the tree until current node
        // has a greater depth.
        let tempNode = previousNode;
        while (tempNode && item[this.PROPERTY_NAME_DEPTH] <= tempNode.content[this.PROPERTY_NAME_DEPTH]) {
          tempNode = tempNode.parent;
        }
        if (tempNode) {
          treeNode.parent = tempNode;
          tempNode.children.push(treeNode);
          // tempNode.isOpen = true;
        } else {
          roots.push(treeNode);
        }
        previousNode = treeNode;
      }
    }
    return roots;
  }


  static buildNode(root, lookupMapOut, propertyNames) {
    const treeNode = new TreeNode();
    treeNode.ID = root[propertyNames[PROPERTY_NAME_ID]];
    treeNode.content = root;

    if (lookupMapOut) {
      lookupMapOut[treeNode.ID] = treeNode;
    }

    const children = root[propertyNames[PROPERTY_NAME_LIST]];
    if (children) {
      for (let i = 0; i < children.length; i++) {
        const childData = children[i];
        const childNode = ExpandingTreeDataHandler.buildNode(childData, lookupMapOut, propertyNames);
        childNode.Parent = treeNode;
        treeNode.children.push(childNode);
      }

      // Don't store original children data.
      delete treeNode.content[propertyNames[PROPERTY_NAME_LIST]];
    }

    return treeNode;
  }
}


// Performant version of concatenating arrays:
// https://jsperf.com/concat-vs-push-apply/10
const ArrayPush = Array.prototype.push;
const PROPERTY_NAME_ID = 'id';
const PROPERTY_NAME_DEPTH = 'depth';
const PROPERTY_NAME_LIST = 'list';


/**
 * TreeeNode
 */
class TreeNode {
  constructor() {
    this.ID;
    this.children = [];
    this.parent;
    this.content = {};
    this.isOpen = false;
  }

  equals(node) {
    return this.ID === node.ID;
  }

  depth() {
    let depth = 0;
    let { parent } = this;
    while (parent) {
      depth++;
      parent = parent.parent;
    }
    return depth;
  }

  isLeaf() {
    return this.children.length === 0;
  }

  flattenSubtree() {
    const nodes = [];
    let childNode;
    for (let i = 0; i < this.children.length; i++) {
      childNode = this.children[i];
      nodes.push(childNode);
      ArrayPush.apply(nodes, childNode.flattenSubtree());
    }
    return nodes;
  }

  /**
     * Recursive list of all children with an open/expanded parent.
     * @param force (boolean) If true, ignores when node 'isOpen' and returns subtree for open state.
     * @returns {Array}
     */
  visibleSubtree(force) {
    const nodes = [];
    if (!this.isOpen && !force) {
      return nodes;
    }

    let childNode;
    for (let i = 0; i < this.children.length; i++) {
      childNode = this.children[i];
      nodes.push(childNode);
      if (childNode.isOpen) {
        ArrayPush.apply(nodes, childNode.visibleSubtree());
      }
    }
    return nodes;
  }

  flattenTree() {
    const tree = this.flattenSubtree();
    tree.unshift(this);
    return tree;
  }
}
