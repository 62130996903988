import React from 'react';
import IconButton from '@unity/react-components/icon-button';
import PropTypes from 'prop-types';

/**
 * Extends IconButton by adding a generic callback to match
 * the 'onChange' interface to other web controls.
 *
 * 'OnChange' returns the assigned data.
 * @param {*} props
 */
const IconOnChangeButton = (props) => {
  function onClick(/* event, value */) {
    if (props.onChange) {
      props.onChange(props.data);
    }
  }

  return (
    <IconButton size="small" color="primary" {...props} onClick={onClick} />
  );
};

IconOnChangeButton.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func,
};

export default (IconOnChangeButton);
