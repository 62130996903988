import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';


const cellLayout = () => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  header: {

    marginBottom: 20,
    display: 'flex',
    paddingLeft: 12,
    backgroundColor: 'rgba(0,0,0,0.1)',

  },
  headerText: {
    alignSelf: 'center',
    marginLeft: 10,
  },
  contentLayout: {
    width: '100%',
    display: 'flex',
  },
  sidePanel: {

    marginRight: 30,

  },
  content: {
    margin: 10,
    flexGrow: 1,
  },
});


const Cell_ = (props) => {
  const radioSelection = (event) => {
    props.onRadioClick(event.target.value);
  };

  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classNames(classes.header, props.className)}>
        <FormControlLabel
          value="end"
          control={(
            <Radio
              checked={props.isSelected}
              onChange={radioSelection}
              value={props.identifier}
              color="default"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'E' }}
              size="small"
            />
)}
          label={props.title}
          labelPlacement="end"
        />
      </div>

      <div className={classes.content}>
        {props.children}
      </div>
    </div>
  );
};

Cell_.propTypes = {
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  identifier: PropTypes.string,
  onRadioClick: PropTypes.func,
};

Cell_.defaultProps = {
  identifier: '',
  onRadioClick: () => {},
  isSelected: false,
};

const Cell = withStyles(cellLayout)(Cell_);
export default Cell;
