
import metricsDataAPI from './metrics-data-api';

const METRIC_KEY = 'uta_agent_sys_disk_bytes_free';
class MetricsHealth {
  constructor() {
    this.isRunning = false;
  }

  notifyWhenMetricsReady(retryInterval) {
    const interval = MetricsHealth._assignInterval(retryInterval);
    const self = this;
    return new Promise((resolve, reject) => {
      if (self.isRunning) {
        setTimeout(function checkRunning() {
          if (self.isRunning) {
            setTimeout(checkRunning, interval);
          } else {
            resolve();
          }
        }, interval);
      } else {
        try {
          self._notifyWhenMetricsReady(interval).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        } catch (error) {
          reject();
        }
      }
    });
  }

  /**
 *
 * @param {Integer} [retryInterval] - Milliseconds
 */
  async _notifyWhenMetricsReady(retryInterval) {
    this.isRunning = true;
    const self = this;
    /* eslint-disable no-await-in-loop */
    for (;;) {
      try {
        const response = await metricsDataAPI.valuesForKeys([METRIC_KEY]);
        if (response[METRIC_KEY] !== 0) {
          self.isRunning = false;
          break;
        } else {
          metricsDataAPI.refresh();
          await MetricsHealth._sleep(retryInterval);
        }
      } catch (error) {
        const message = new ResponseError.message(error, { network: true });
        self.isRunning = false;
        throw new Error(message);
      }
    }
  }

  static _sleep(retryInterval) {
    return new Promise((resolve) => {
      setTimeout(() => { resolve('Success!'); }, retryInterval);
    });
  }

  static _assignInterval(retryInterval) {
    let interval = retryInterval;
    if (!retryInterval) {
      interval = 2000;
    }
    return interval;
  }
}

const metricsHealthInstance = new MetricsHealth();
export default metricsHealthInstance;
