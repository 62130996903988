import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PageHeader from '@unity/react-components/page-header/page-header';
import Button from '@unity/react-components/button';
import WarningIcon from '@material-ui/icons/Warning';
import palette from '@unity/react-components/theme/palette';
import Typography from '@unity/react-components/typography';
import Banner from '@unity/react-components/banner';
import ExpandableList from '../components/configuration-page/expandable-list';
import configurationDataAPI from '../data/configuration-data-api';
import restartMonitor from '../data/restart-monitor';
import { DataLists } from '../data/config-lists-ui-data';
import AgentRestartModal from '../components/configuration-page/modals/agent-restart-modal';
import StateCard from '../modified-unity-library-components/state-card';
import { COMPLETED_STATE, FAILED_STATE } from '../helpers/constants';
import { ResponseError } from '../data/api-cache';

const infoBoxStyle = {
  margin: 16,
};

const PROGRESS_MODAL_SUCCESS_TEXT = 'Restart completed.';
const PROGRESS_MODAL_PROGRESS_TEXT = 'Please wait.  Will take 30 - 60 secs approximately.';

const ConfigurationsPage = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isAuthenticating: false,
    message: '',
  });

  const [restartState, setRestartState] = useState({
    showRestartAction: false,
    isRestarting: false,
    showProgressModal: false,
    progressModalText: '',
    newUrl: null,
    reasonsText: '',
  });

  function onRestartChange(isRestartNeeded, restartReasons) {
    setRestartState({
      ...restartState,
      showRestartAction: isRestartNeeded,
      reasonsText: restartReasons.join("\n"),
    });
  }

  async function authenticate() {
    try {
      setAuthState({ isAuthenticated: false, isAuthenticating: true });
      const status = await configurationDataAPI.refresh();
      const authenticated = (status.state === COMPLETED_STATE);
      let message = '';
      if (!authenticated) {
        if (status.error.statusCode === 401) {
          message = "Tip: additional credentials can be set via the unity-accelerator CLI tool.  See its 'tool' command for information.";
        } else {
          message = ResponseError.message(status.error);
        }
      }
      setAuthState({ isAuthenticated: authenticated, isAuthenticating: false, message });
    } catch (error) {
      setAuthState({ isAuthenticated: false, isAuthenticating: false });
    }
  }

  useEffect(() => {
    authenticate();
    restartMonitor.addRestartChangeCallback(onRestartChange);
    setRestartState({
      ...restartState,
      showRestartAction: restartMonitor.hasPendingRestart,
    });

    return function cleanup() {
      restartMonitor.removeRestartChangeCallback(onRestartChange);
    };
  }, []);

  function onRestartClick() {
    setRestartState({
      ...restartState,
      isRestarting: true,
      showProgressModal: true,
      progressModalText: PROGRESS_MODAL_PROGRESS_TEXT,
    });
    restartMonitor.restartAccelerator(onAcceleratorRestart);
  }

  function onAcceleratorRestart(info, error) {
    let message = PROGRESS_MODAL_SUCCESS_TEXT;
    let url = null;
    if (error == null) {
      if (parseInt(window.location.port, 10) !== info.port) {
        url = `${window.location.protocol}//${window.location.hostname}:${info.port}${window.location.pathname}${window.location.hash}`;
      }
    } else {
      message = `Server error: ${error}`;
    }

    setRestartState({
      ...restartState,
      isRestarting: false,
      showRestartAction: !info.didRestart,
      showProgressModal: true,
      progressModalText: message,
      newUrl: url,
    });
  }

  function didCloseRestartModal() {
    setRestartState({
      ...restartState,
      showProgressModal: false,
    });

    if (restartState.newUrl) {
      window.location.assign(restartState.newUrl);
    }
  }

  const actions = (
    <Button color="primary" variant="contained" onClick={() => onRestartClick()}> Restart Agent</Button>
  );

  function contentUI() {
    let content;
    if (authState.isAuthenticated) {
      content = <>{DataLists.map((list) => <ExpandableList data={list} key={list.listHeader.id} />)}</>;
    } else if (authState.isAuthenticating) {
      content = (
        <StateCard
          title="Loading Configuation information"
          isLoading
        />
      );
    } else {
      content = (
        <StateCard
          title="Failed Loading Configuations"
          description={authState.message}
          ButtonProps={{
            color: 'primary',
            label: 'Retry',
            onClick: () => { authenticate(); },
          }}
        />
      );
    }
    return content;
  }


  return (
    <div>
      <PageHeader title="Configuration" />
      { restartState.showRestartAction && authState.isAuthenticated
        && (
        <div style={infoBoxStyle}>
          <Banner
            state="warn"
            buttons={actions}
            content={(
              <>
                <WarningIcon style={{ color: palette.warn.main }} />
                <Typography variant="body">
                  An Agent restart is required for changes to take effect.
                  <pre>{ restartState.reasonsText }</pre>
                </Typography>
              </>
            )}
          />
        </div>
        )}
      { contentUI() }
      {restartState.showProgressModal && <AgentRestartModal canClose={!restartState.isRestarting} onClose={didCloseRestartModal} description={restartState.progressModalText} />}
    </div>
  );
};

const styles = () => ({
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles)(ConfigurationsPage);
