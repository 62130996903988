import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PageHeader from '@unity/react-components/page-header/page-header';
import Button from '@unity/react-components/button';
import Card from '@material-ui/core/Card';
import Overflow from '@unity/react-components/overflow/overflow';
import appAxios from '../data/app-axios';
import StateCard from '../modified-unity-library-components/state-card';
import { makeCancelable } from '../helpers/cancellable-promise';
import { AxiosMessage } from '../data/api-cache';

const logsEndpointPath = '/api/logs';
let logsRequestPromise = null;
const maxLogsDisplayed = 2000;

/**
 * https://stackoverflow.com/questions/30765163/pretty-printing-json-with-react
 * @param {*} props
 */
const LogsPage = (props) => {
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    isRequestInProgress: false,
    showError: false,
    errorMessage: '',
  });

  const [logData, setLogData] = useState({
    serverResponse: null,
    translatedData: null,
  });

  function convertFileStringToObject(text) {
    const _lines = text.split('\n');
    const arr = [];

    for (let i = 0; i < _lines.length; i += 1) {
      const line = _lines[i];
      if (line.length !== 0) {
        try {
          const obj = JSON.parse(_lines[i]);
          arr.push(obj);
        } catch (error) {
          // Ignore handling parse error and proceed
          // row will be missing from display.
        }
      }
    }
    return arr;
  }

  function fetchLogs() {
    if (loadingState.isRequestInProgress) {
      return;
    }
    setLoadingState((prevState) => ({
      ...prevState,
      isRequestInProgress: true,
    }));

    logsRequestPromise = makeCancelable(appAxios.get(logsEndpointPath));
    logsRequestPromise.promise
      .then((response) => {
        setLogData((prevState) => ({
          ...prevState,
          serverResponse: response.data,
          translatedData: convertFileStringToObject(response.data),
        }));

        setLoadingState((prevState) => ({
          ...prevState,
          isLoading: false,
          showError: false,
          isRequestInProgress: false,
        }));
      }).catch((error) => {
        const errorMessage = AxiosMessage.serverMessage(error) || AxiosMessage.networkMessage(error);
        setLogData((prevState) => ({
          ...prevState,
          serverResponse: null,
          translatedData: null,
        }));

        setLoadingState((prevState) => ({
          ...prevState,
          isLoading: false,
          showError: true,
          isRequestInProgress: false,
          errorMessage: `Server message: ${errorMessage}`,
        }));
      });
  }

  function onUserDownloadRequest() {
    if (loadingState.isLoading) {
      return;
    }

    const url = window.URL.createObjectURL(new Blob([logData.serverResponse]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'logs.txt');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }

  useEffect(() => {
    fetchLogs();
    return function cleanup() {
      logsRequestPromise.cancel();
      logsRequestPromise = null;
    };
  }, []);

  const actions = (
    <Button color="primary" variant="contained" disabled={loadingState.showError || loadingState.isLoading} onClick={() => onUserDownloadRequest()}>Download</Button>
  );

  // Required to pass lint: react/no-unused-prop-types
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/no-unused-prop-types.md#false-positives-sfc
  const { classes } = props;

  function logsUI() {
    let template = null;

    if (loadingState.isLoading) {
      template = (
        <StateCard
          title="Loading Log File"
          isLoading
        />
      );
    } else if (loadingState.showError) {
      template = (
        <StateCard
          title="Failed Loading Log File"
          description={loadingState.errorMessage}
          ButtonProps={{
            color: 'primary',
            label: 'Retry',
            onClick: () => { fetchLogs(); },
          }}
        />
      );
    } else {
      template = (
        <pre className={classes.preStyle} key="abc">
          {/* Dislpay a max length of items otherwise page blocks while rendering. */}
          {JSON.stringify(logData.translatedData.slice(0, Math.min(maxLogsDisplayed, logData.translatedData.length)), null, 2)}
        </pre>
      );
    }
    return template;
  }

  return (
    <div>
      <PageHeader title="Logs" actions={actions} />
      <Card>
        <Overflow>
          <div style={{ maxHeight: 700 }}>
            { logsUI() }
          </div>
        </Overflow>
      </Card>
    </div>
  );
};

LogsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  preStyle: {
    display: 'block',
    padding: '10px 30px',
    margin: '0',
    overflow: 'scroll',
  },
});

export default withStyles(styles)(LogsPage);
