import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, ClockView } from '@material-ui/pickers';
import SkeletonLoader from '@unity/react-components/skeleton-loader/skeleton-loader';
import configurationDataAPI from '../../../data/configuration-data-api';
import { makeCancelable } from '../../../helpers/cancellable-promise';
import * as constants from '../../../helpers/constants';
import ErrorText from '../../../app-wide-controls/error-text';
import ClockControls from './clock-controls';
import { ResponseError } from '../../../data/api-cache';

/**
 *
 *
 * https://material-ui-pickers.dev/guides/static-components
 * @param {*} props
 */
const TimeEditor = (props) => {
  const [state, setState] = useState({
    date: new Date(),
    hourText: null,
    minuteText: null,
    clockType: constants.HOURS,
    isAM: false,
    isLoading: true,
    isErrored: false,
    errorMessage: null,
  });

  let _isAM = null;
  function isAM() {
    if (_isAM !== null) {
      return _isAM;
    }
    return state.isAM;
  }
  function setIsAM(isAM) {
    _isAM = isAM;
    setState((prevState) => ({ ...prevState, isAM }));
  }

  function setIsLoading(isLoading) {
    setState((prevState) => ({ ...prevState, isLoading }));
  }

  function onHourSelected() {
    setState((prevState) => ({ ...prevState, clockType: constants.HOURS }));
  }

  function onMinuteSelected() {
    setState((prevState) => ({ ...prevState, clockType: constants.MINUTES }));
  }

  function onHourChange(date) {
    let hourInt = date.getHours() % 12;
    if (hourInt === 0) {
      hourInt = 12;
    }
    setState((prevState) => ({ ...prevState, date, hourText: hourInt.toString() }));
    didEdit(date);
  }

  function onMinuteChange(date) {
    const minutes = date.getMinutes();
    let _minuteText = minutes.toString();
    if (minutes < 10) {
      _minuteText = `0${minutes.toString()}`;
    }
    setState((prevState) => ({ ...prevState, date, minuteText: _minuteText }));
    didEdit(date);
  }

  function onMeridiemChange(meridiem) {
    const isAM = meridiem === constants.AM_TIME;
    setIsAM(isAM);
    didEdit(state.date);
  }

  function didEdit(date) {
    let hours = date.getHours();
    if (!isAM() && hours < 12) {
      hours += 12;
    } else if (isAM() && hours >= 12) {
      hours -= 12;
    }

    const _userValue = {
      hourAs24hr: hours,
      [constants.MINUTE]: date.getMinutes(),
    };
    setState((prevState) => ({ ...prevState, userValue: _userValue }));
    if (props.onEditListener) {
      props.onEditListener({ [props.dataID]: _userValue });
    }
  }

  useEffect(() => {
    setIsLoading(true);

    let fetchRequest;
    (async function () {
      try {
        fetchRequest = makeCancelable(configurationDataAPI.valuesForKeys(props.dataID));
        const { [props.dataID]: data } = await fetchRequest.promise;
        const timeInfo = data.toViewModel();

        props.onEditListener({ [props.dataID]: timeInfo });
        const date = new Date();
        date.setHours(timeInfo.hourAs24hr);
        date.setMinutes(timeInfo[constants.MINUTE]);
        setIsAM(timeInfo.hourAs24hr < 12);
        onHourChange(date);
        onMinuteChange(date);
        setIsLoading(false);
      } catch (error) {
        const message = ResponseError.message(error);
        setState((prevState) => ({ ...prevState, isErrored: true, errorMessage: message, isLoading: false }));
        props.onEditListener(null);
      }
    }());

    return function cleanup() {
      if (fetchRequest) {
        fetchRequest.cancel();
      }
      _isAM = null;
    };
  }, [props.dataID]);

  function stateUI() {
    if (state.isLoading) {
      return <SkeletonLoader width={250} height={20} gutterBottom="small" rounded />;
    }
    if (state.isErrored) {
      return <ErrorText text={state.errorMessage} />;
    }
    return null;
  }

  let CSSCanShowClock = props.classes.show;
  if (state.isLoading || state.isErrored) {
    CSSCanShowClock = props.classes.hide;
  }

  return (
    <>
      { stateUI() }
      <div className={CSSCanShowClock}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <ClockControls
              hour={state.hourText}
              minute={state.minuteText}
              isMorning={state.isAM}
              onMinuteSelection={onMinuteSelected}
              onHourSelection={onHourSelected}
              onMeridiemSelection={onMeridiemChange}
            />
            <ClockView // or just directly use components
              type={state.clockType}
              date={state.date}
              ampm
              onMinutesChange={(date) => { onMinuteChange(date); }}
              onSecondsChange={(date) => { onMinuteChange(date); }}
              onHourChange={(date) => onHourChange(date)}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
};

TimeEditor.propTypes = {
  dataID: PropTypes.string,
  onEditListener: PropTypes.func.isRequired,
  isLoadingListener: PropTypes.func,
};

const styles = ({ unityTypography }) => ({
  caption: unityTypography.caption,
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
});

export default withStyles(styles)(TimeEditor);
