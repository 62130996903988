import React from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/flow';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import withOpenability, { openableShape } from '@unity/react-components/hoc/with-openability';

import UnityLogo from '@unity/react-components/icons/unity-name-logo';
import HeaderContainer from '@unity/react-labs/app-header/container';
import HeaderLogo from '@unity/react-labs/app-header/logo';
import { AppBar } from '@material-ui/core';

/**
 * Modified example code from:
 * https://unitytech.github.io/unity-frontend-lib-docs/docs/@unity/react-components/3.10.0/index.html?page=components/header
 * Slack: #unity-frontend-lib @joona @maciej
 */
class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        userMenuOpenable: openableShape.isRequired,
    };

    render() {
        const { classes, overflowMenu } = this.props;

        return (
            <AppBar>
                <HeaderContainer>
                    <div className={classes.leftSideContainer}>
                    <Hidden mdUp>
                        {overflowMenu}
                    </Hidden>
                    <Hidden smDown>
                        <HeaderLogo href="/dashboard" logo={UnityLogo} className={classes.logo} />
                        <h3 className={classes.headerText}>Accelerator</h3>
                    </Hidden>
                    </div>
                </HeaderContainer>
            </AppBar>
        );
    }
}

const styles = ({ mixins, spacing, palette }) => ({
  logo: {
    ...mixins.size('auto', 28),
    marginLeft: spacing(2.5),
    color: palette.common.black,
    lineHeight: 0,
  },
  leftSideContainer: {
    ...mixins.flex('center'),
    flexWrap: 'nowrap',
    height: '100%',
  },
  rightSideContainer: {
    ...mixins.flex('center', 'flex-end'),
    flexWrap: 'nowrap',
    height: '100%',
  },
  headerText: {
    color: "rgba(0, 0, 0, 0.87)",
    paddingLeft: "20px",
  }
});

export default flow(withOpenability('userMenuOpenable'), withStyles(styles))(Header);