
import appAxios from './app-axios';


class AgentHealth {
  constructor() {
    this.isRunning = false;
  }

  notifyWhenAgentRunning(port, retryInterval) {
    const interval = AgentHealth._assignInterval(retryInterval);
    const self = this;
    return new Promise((resolve, reject) => {
      if (self.isRunning) {
        setTimeout(function checkRunning() {
          if (self.isRunning) {
            setTimeout(checkRunning, interval);
          } else {
            resolve();
          }
        }, interval);
      } else {
        try {
          self._notifyWhenAgentRunning(port, interval).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        } catch (error) {
          reject();
        }
      }
    });
  }

  async _notifyWhenAgentRunning(port, retryInterval) {
    this.isRunning = true;
    const self = this;
    /* eslint-disable no-await-in-loop */
    for (;;) {
      try {
        const url = `${window.location.protocol}//${window.location.hostname}:${port}/api/agent-health`;
        await appAxios.get(url);
        self.isRunning = false;
        break;
      } catch (error) {
        // No action on error - keep trying indefinitely.
        await AgentHealth._sleep(retryInterval);
      }
    }
  }

  static _sleep(retryInterval) {
    return new Promise((resolve) => {
      setTimeout(() => { resolve('Success!'); }, retryInterval);
    });
  }

  static _assignInterval(retryInterval) {
    let interval = retryInterval;
    if (!retryInterval) {
      interval = 1000;
    }
    return interval;
  }
}

const agentHealthInstance = new AgentHealth();
export default agentHealthInstance;
