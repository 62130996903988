import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import withOpenability from '@unity/react-components/hoc/with-openability';
import flow from 'lodash/flow';

// Hack.  How to create one theme for all components to access?
import unityTheme from '@unity/react-components/theme';
import { createMuiTheme } from '@material-ui/core/styles';
import SideMenuDrawer from './side-menu-drawer';
import SideMenu from './side-menu';
import Header from './header';


const theme = createMuiTheme(unityTheme);


/**
 * Partitions the page into sections held through the application lifecycle:
 *  -   Header (owns the overflow menu)
 *  -   Main Page (subdivded into):
 *      -   Side Menu
 *      -   Content (details)
 */
class SiteLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, openable } = this.props;
    return (
      <div className={classes.root}>
        <Header overflowMenu={(
          <SideMenuDrawer isOpen={openable.open} handleClose={openable.handleClose} handleOpen={openable.handleOpen}>
            <SideMenu onSelectionCallback={openable.handleClose} />
          </SideMenuDrawer>
                  )}
        />
        <div className={classes.belowHeaderSpace}>
          <Hidden smDown>
            <div className={classes.sideMenuCustomization}>
              <SideMenu />
            </div>
          </Hidden>
          <Container maxWidth="xl" className={classes.mainContent}>
            {this.props.children}
          </Container>
        </div>
      </div>
    );
  }
}

SiteLayout.propTypes = {
  classes: PropTypes.object,
};

const styles = ({ breakpoints }) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),

  },
  belowHeaderSpace: {
    paddingTop: '56px',
    paddingLeft: '250px',
    [breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  sideMenuCustomization: {
    position: 'fixed',
    top: '56px',
    bottom: '0',
    left: '0',
  },
  mainContent: {
    paddingTop: '42px',
    paddingBottom: '42px',
  },
});

export default flow(withStyles(styles), withOpenability())(SiteLayout);
